import { ExcelAxisItem, ExcelState, Subscribe, SubscribeFixedRowCell } from 'interfaces/excelTable.interfaces'

export const parseFixedRows = <State extends ExcelState = ExcelState>(
  rows: ExcelAxisItem[][] | undefined,
  subscribe: Subscribe<State, any> | undefined,
  getState: (() => State) | undefined,
  page: number,
  perPage: number,
  countX: number,
  countY: number,
  paginateAxis: 'x' | 'y',
): { cellsWithSubscriber: SubscribeFixedRowCell[][] } => {
  if (!page) {
    return { cellsWithSubscriber: [] }
  }

  if (!rows) {
    const count = paginateAxis === 'y' ? countY : countX
    const lastPage = Math.ceil(count / perPage)
    const lastPageCount = count - (page - 1) * perPage
    rows = Array.from({ length: lastPage === page ? lastPageCount : perPage }).map(() =>
      Array.from({ length: getState?.().data?.[1]?.rows[0].length || 0 }),
    )
  }

  const cellsWithSubscriber = rows.reduce((newArray: SubscribeFixedRowCell[][], row, indexRow) => {
    newArray.push(
      row.map((cell, indexColumn) => {
        if (!cell) {
          cell = {} as any
        }
        ;(cell as any).code =
          (cell?.code ?? paginateAxis === 'y') ? getState?.().data?.[1]?.rows[0]?.[indexColumn]?.code : undefined
        return {
          subscribeCell: (listener: (selectedState: any, previousSelectedState: any) => void) =>
            subscribe?.(({ data }) => data?.[page]?.rows[indexRow]?.[indexColumn], listener),
          subscribeWidth: (listener: (selectedState: any, previousSelectedState: any) => void) =>
            subscribe?.(({ data, widthColumns }) => {
              const cellData = data?.[page]?.rows[0]?.[indexColumn]
              const key = cellData?.code ?? data?.[1]?.rows[0]?.[indexColumn]?.code
              return key ? widthColumns.widths?.[key] : undefined
            }, listener),
          getWidth: () => {
            const state = getState?.()
            const cellData = state?.data?.[page]?.rows[0]?.[indexColumn]
            const key = cellData?.code ?? state?.data?.[1]?.rows[0]?.[indexColumn]?.code
            return key ? state?.widthColumns.widths?.[key] || null : null
          },
          getInitCell: () => {
            let initCell = getState?.().data?.[page]?.rows[indexRow]?.[indexColumn]
            if (!initCell) {
              initCell = {} as ExcelAxisItem
            }
            ;(initCell as any).code =
              (initCell?.code ?? paginateAxis === 'y')
                ? getState?.().data?.[1]?.rows[0]?.[indexColumn]?.code
                : undefined
            return initCell
          },
        }
      }),
    )
    return newArray
  }, [])

  return { cellsWithSubscriber }
}
