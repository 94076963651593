import { createElement } from 'react'

import cx from 'clsx'
import { findLastIndex } from 'lodash-es'
import classes from 'packages/ui/Draggable/Draggable.module.scss'
import { calculateMovingData } from 'packages/ui/Draggable/helpers/calculateMovingData'
import { PlaceholderStrategy } from 'packages/ui/Draggable/types'

import { allLevelsStrategy } from './allLevelsStrategy'

export const placeholderBetweenStrategy: PlaceholderStrategy = (params) => {
  const {
    levelsStrategy,
    childrenList,
    placeholderElement,
    draggableIndex,
    classNamePlaceholder,
    refInitialIndex,
    refRectItem,
    refPlaceholder,
    refDraggableItem,
    refElementPosition,
    refPositions,
    refRealDraggableIndex,
    refList,
    refIsOtherList,
  } = params

  if (refInitialIndex.current !== null && refRectItem.current && !refIsOtherList.current) {
    childrenList.splice(
      refInitialIndex.current,
      0,
      createElement(placeholderElement, {
        className: cx(classes.placeholder, classNamePlaceholder),
        'data-list-element': true,
        'data-placeholder-element': true,
        'data-initial-draggable-index': refInitialIndex.current,
        'data-draggable-index': draggableIndex,
        key: 'placeholder',
        style: { width: refRectItem.current.width, height: refRectItem.current.height },
        ref: refPlaceholder,
      }),
    )

    const { canInsert } = levelsStrategy ?? allLevelsStrategy(params)

    if (draggableIndex !== null && canInsert()) {
      childrenList.splice(
        draggableIndex,
        0,
        createElement(placeholderElement, {
          className: classes.between,
          'data-between-element': true,
          'data-initial-draggable-index': refInitialIndex.current,
          'data-draggable-index': draggableIndex,
          key: 'between',
        }),
      )
    }
  }
  const calculateIndex = () => {
    if (
      !refDraggableItem.current ||
      !refElementPosition.current ||
      !refPositions.current ||
      !refList.current ||
      refRealDraggableIndex.current === null
    ) {
      return 0
    }
    const { positions, listTop } = calculateMovingData(params)
    const top = refDraggableItem.current.getBoundingClientRect().top - listTop
    const positionItem = top + (refRectItem.current?.height || 0)
    const isUp = refElementPosition.current.startPosition > top
    let index = isUp
      ? refPositions.current.findIndex((item) => item > positionItem)
      : findLastIndex(positions, (item) => item < positionItem)
    if (index === -1) {
      index = isUp ? refPositions.current.length : 0
    }
    refRealDraggableIndex.current = index + (isUp ? -1 : 0)
    return index + (isUp ? -1 : 1)
  }

  return {
    childrenList,
    calculateIndex,
  }
}
