import { isServerError } from '@jume/api'
import { CustomAxiosInstance, GetOneResponse } from 'packages/core/apiConfig'

import { isTokensDefined } from './isTokensDefined'
import { RefreshException } from './refreshException'
import { requestBroadcastTokens } from './requestBroadcastTokens'
import { tokensEndpoints } from './tokensEndpoints'
import { tokensStorage } from './tokensStorage'
import { RefreshData, TokenResponse, Tokens } from './types'

export const refreshTokens = async (axios: CustomAxiosInstance) => {
  const { refreshToken } = tokensStorage.get()

  if (!refreshToken) {
    throw new RefreshException()
  }

  const refresh = async (form: RefreshData): GetOneResponse<TokenResponse> => {
    const { data } = await axios.request<TokenResponse>({
      method: 'POST',
      ...tokensEndpoints.refresh(),
      data: form,
      tracing: {
        stream: null,
        module: 'core.auth',
        methodName: 'refresh',
      },
    })
    return data
  }

  const tokens = await refresh({ refresh: refreshToken })
    .then(
      (data): Tokens => ({
        accessToken: data.access,
        refreshToken: data.refresh,
      }),
    )
    .catch(async (error) => {
      if (isServerError(error)) {
        throw error
      }
      if (error.status === 401) {
        throw new RefreshException()
      }
      const broadcastTokens = await requestBroadcastTokens()
      if (!isTokensDefined(broadcastTokens)) {
        throw new RefreshException()
      }
      return broadcastTokens
    })

  if (isTokensDefined(tokens)) {
    tokensStorage.set(tokens)
    axios.setToken(tokens.accessToken)
  } else {
    throw new RefreshException()
  }

  return { tokens }
}
