import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { t } from '@jume/localization'
import { useDeepCompareEffect } from 'ahooks'
import { Button, ButtonColors, ButtonSizes } from 'packages/ui/Button'
import { CanvasJsChartOptions, ChartRef, StockChart } from 'packages/ui/Chart'
import { Loader, LoaderTypes } from 'packages/ui/Loader'
import { MarkItem, MarkItemTypes } from 'packages/ui/MarkItem'
import { Tag } from 'packages/ui/Tag'

import { ExcelTableContext } from './context/ExcelTableContext'
import classes from './ExcelChart.module.scss'
import { createExcelChartOptions } from './helpers/createExcelChartOptions'

interface ExcelChartProps {
  isLoading?: boolean
  showChart?: boolean
  setShowChart?: (show: boolean) => void
}

export const ExcelChart: FC<ExcelChartProps> = ({ isLoading: newIsLoading }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { tableState } = useContext(ExcelTableContext)
  const setShowChart = tableState?.getState?.().setShowChart
  const showChart = tableState?.getState?.().showChart
  const tableData = tableState?.getState?.().data
  const invalidFilterText = tableState?.invalidFilterText
  const filter = tableState.filterState?.getState().filter
  const setFilter = tableState.filterState?.getState().setFilter
  const columnToChart = tableState.chartOptions?.columnToChart || {}
  const columnName = tableState.chartOptions?.columnName
  const data = tableData || {}
  const isFirstUpdate = useRef(true)
  const isFirstLoadingUpdate = useRef(true)
  const [visibleLines, setVisibleLines] = useState<Record<string, boolean>>({})
  const refChart = useRef<ChartRef>(null)
  const getChangedCell = tableState.stateChangedCells?.().getByLocation
  const changedCells = tableState.stateChangedCells?.().changedCells

  useEffect(() => {
    if (isFirstLoadingUpdate.current) {
      isFirstLoadingUpdate.current = false
    } else {
      setIsLoading(!!newIsLoading)
    }
  }, [newIsLoading])

  const invalidFilters = useMemo(() => {
    const key = Number(Object.keys(data).at(-1)) || 0
    const rows = data[key]?.rows
    const values = rows?.[0].map((cell) => cell.value)
    return !rows?.every((row) => row.every((cell, index) => cell.value === values?.[index] || cell.name === columnName))
  }, [filter, data])

  useDeepCompareEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false
    } else {
      refChart.current?.toggleVisibilityLines(visibleLines)
    }
  }, [visibleLines])

  useEffect(() => {
    if (showChart) {
      const key = Number(Object.keys(data).at(-1)) || 0
      const rows = data[key]?.rows

      const newVisibleCharts = rows?.reduce((acc, row) => {
        const column = String(row.find((cell) => cell.name === columnName)?.value)
        const keyGroup = Object.keys(columnToChart)
        if (!keyGroup.includes(column)) {
          return acc
        }
        return {
          ...acc,
          [column]: true,
        }
      }, {})
      setVisibleLines(newVisibleCharts)
    }
  }, [showChart])

  const options = useMemo(() => {
    if (isLoading) {
      return {} as CanvasJsChartOptions
    }
    return createExcelChartOptions(data, tableState, visibleLines, getChangedCell)
  }, [data, tableState.stateChangedCells, visibleLines, isLoading, changedCells])

  const toggleVisibleLine = (name: string) => {
    setVisibleLines({
      ...visibleLines,
      [name]: !visibleLines[name],
    })
  }

  const onClose = () => {
    setShowChart?.(false)
    setFilter?.({})
  }

  useEffect(
    () => () => {
      onClose()
    },
    [],
  )

  return (
    <div className={classes.excelChart}>
      <div className={classes.top}>
        <div className={classes.tags}>
          {!invalidFilters &&
            !isLoading &&
            options?.charts.at(-1)?.data?.map((chart) => (
              <Tag
                className={classes.legendItem}
                colorHex={chart.color}
                gradient={!!chart.name && columnToChart[chart.name].gradient}
                isBorder={!visibleLines[String(chart.name)]}
                isClickable
                key={chart.legendText}
                onClick={() => toggleVisibleLine(String(chart.name))}
              >
                {chart.legendText}
              </Tag>
            ))}
        </div>
        <Button color={ButtonColors.Border} onClick={onClose} size={ButtonSizes.Small}>
          {t('close')}
        </Button>
      </div>

      {invalidFilters && options?.charts?.[0]?.data?.length && (
        <div className={classes.info}>
          <MarkItem type={MarkItemTypes.Info}>{invalidFilterText}</MarkItem>
        </div>
      )}

      {!invalidFilters && !options?.charts?.[0]?.data?.length && !isLoading && (
        <div className={classes.info}>
          <MarkItem type={MarkItemTypes.Info}>{tableState.chartOptions?.emptyChartText}</MarkItem>
        </div>
      )}

      {isLoading && (
        <div className={classes.info}>
          <Loader type={LoaderTypes.SpinnerLarge} />
        </div>
      )}

      {options && !isLoading && !invalidFilters && options?.charts?.[0]?.data?.length && (
        <StockChart className={classes.chart} options={options} refChart={refChart} />
      )}
    </div>
  )
}
