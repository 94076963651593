import { SubscribeColumnCell, TableState } from 'interfaces/excelTable.interfaces'
import { sum } from 'lodash-es'

import { rotateMatrix } from './rotateMatrix'

export const setPositionsColumns = (
  headerCells: (SubscribeColumnCell | null)[][],
  tableState: TableState,
  page: number,
  perPage: number | undefined,
  paginateAxis: 'x' | 'y',
) => {
  const getLeftInit = (firstIndex: number) => {
    const widths = tableState.storageWidths?.getState() || {}
    const prevWidths = Object.entries(widths)
      .filter(([index]) => Number(index) < firstIndex)
      .map(([_, width]) => width)
    const beforeCount =
      page === 1 ? prevWidths.length : (paginateAxis === 'x' ? (page - 1) * (perPage || 0) : 0) - prevWidths.length
    return sum(prevWidths) + beforeCount * tableState.defaultWidthColumn
  }

  const getLeft = (firstIndex: number, item: SubscribeColumnCell, leftInit: number) => {
    const widths = tableState.storageWidths?.getState() || {}
    const prevWidths = Object.entries(widths)
      .filter(([index]) => Number(index) >= firstIndex && Number(index) < item.index)
      .map(([_, width]) => width)
    const leftPrev = sum(prevWidths)
    const beforeCount =
      page === 1 ? prevWidths.length : (paginateAxis === 'x' ? (page - 1) * (perPage || 0) : 0) - prevWidths.length
    return tableState.defaultWidthColumn * (item.index - beforeCount) + leftPrev + leftInit
  }

  rotateMatrix(headerCells).forEach((column, indexRow) => {
    const firstIndex = column[0]?.index || 0
    const leftInit = getLeftInit(firstIndex)

    column.forEach((item) => {
      if (!item) {
        return
      }
      const left = getLeft(firstIndex, item, leftInit)

      if (!tableState.columns[item.key]) {
        tableState.columns[item.key] = {} as any
      }
      tableState.columns[item.key].position = {
        top: tableState.heightRow * indexRow,
        left,
      }
      tableState.columns[item.key].isMounted = false
      tableState.columns[item.key].setIsMounted = (isMounted) => {
        tableState.columns[item.key].isMounted = isMounted
      }
      tableState.columns[item.key].isCalculated = false
      tableState.columns[item.key].setCalculated = () => {
        tableState.columns[item.key].isCalculated = true
      }
      tableState.columns[item.key].recalculateLeft = () => {
        const newLeftInit = getLeftInit(firstIndex)
        tableState.columns[item.key].position.left = getLeft(firstIndex, item, newLeftInit)
      }
    })
  })

  tableState.recalculateColumnsLeft = () => {
    Object.values(tableState.columns)
      .filter((cell) => cell.isMounted)
      .forEach((cell) => cell.recalculateLeft?.())
  }
}
