import { SpanStatusCode } from '@opentelemetry/api'
import { snakeCase } from 'lodash-es'
import { GlobalSettings } from 'packages/core/config'

import { defaultSpanOptions } from './defaultSpanOptions'
import { limitStringLength } from './helpers/limitStringLength'
import { tracer } from './tracer'

export const sendWsTracing = (attributes: {
  data: any
  channel: string
  userId?: number
  tracing?: Record<string, string | number | null>
  stream?: GlobalSettings['streams'] | null
  module?: string
  size?: string
}) => {
  const span = tracer.startSpan('ws_publish', defaultSpanOptions)
  let data = String(attributes.data)
  try {
    data = JSON.stringify(attributes.data)
  } catch (e) {}

  span.setAttribute('publish_data', limitStringLength(data))
  span.setAttribute('channel', attributes.channel)
  if (attributes.stream) {
    span.setAttribute('stream', attributes.stream)
  }
  if (attributes.module) {
    span.setAttribute('module', attributes.module)
  }
  if (attributes.size) {
    span.setAttribute('size', attributes.size)
  }
  if (attributes.userId) {
    span.setAttribute('user_id', attributes.userId)
  }
  if (attributes.tracing) {
    Object.entries(attributes.tracing).forEach(([key, value]) => {
      span.setAttribute(
        snakeCase(key),
        typeof value === 'string' ? limitStringLength(value) : value === null ? 'null' : value,
      )
    })
  }
  span.setStatus({ code: SpanStatusCode.OK })
  span.end()
}
