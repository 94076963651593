import { Dispatch, Fragment, MouseEvent, MouseEventHandler, SetStateAction } from 'react'

import { t } from '@jume/localization'
import { toArray } from '@jume/utils'
import ArrowIcon from 'assets/images/arrow-list.svg?react'
import cx from 'clsx'
import { OptionTreeItem } from 'interfaces/components.interfaces'
import { flatten } from 'lodash-es'
import { Button, ButtonColors, ButtonSizes, ItemsTreeParseItem } from 'packages/ui'
import { Option } from 'rc-select'
import { findCheckedAllChildren, findCheckedDeep, findCheckedDeepByParentIds } from 'utils/filter'

const getChildrenItem = (key?: string | number | null, element?: OptionTreeItem): (string | number | null)[] => {
  if (!key || !element) {
    return []
  }
  return [element.value, ...flatten(element.items?.map((item) => getChildrenItem(item.value, item)) || [])].filter(
    Boolean,
  ) as (string | number | null)[]
}

interface ItemsTreeParseProps {
  itemsTree: OptionTreeItem[] | undefined
  openTreeItems: (string | number | null)[]
  setOpenTreeItems: Dispatch<SetStateAction<(string | number | null)[]>>
  isSearch: boolean
  level?: number
  valueParent?: string | number | null
  onOpenTree?: (key: string | number | null) => void
  checkedValues?: string | number | null | (string | number)[]
  selectedParentIds?: Record<number, number[]>
  isSelectable?: boolean
  isFirstLevel?: boolean
  multiple?: boolean
  selectedOptions?: OptionTreeItem[]
  checked?: boolean
  isSelectWithChildren?: boolean
  checkParentWithCheckedChildren?: boolean
}

export const itemsTreeParse = ({
  itemsTree,
  openTreeItems,
  setOpenTreeItems,
  isSearch,
  level = 1,
  valueParent,
  onOpenTree,
  checkedValues,
  selectedParentIds,
  isSelectable,
  isFirstLevel,
  multiple,
  selectedOptions,
  checked,
  isSelectWithChildren,
  checkParentWithCheckedChildren,
}: ItemsTreeParseProps) => {
  const ItemElement = isSelectable ? Option : ItemsTreeParseItem

  const onToggle =
    (key?: string | number | null, element?: OptionTreeItem): MouseEventHandler =>
    (e) => {
      if (!key) {
        return
      }
      if (onOpenTree) {
        onOpenTree(key)
      }
      e.stopPropagation()
      setOpenTreeItems((prev) => {
        if (prev.includes(key)) {
          return [...prev].filter((item) => !getChildrenItem(key, element).includes(item))
        }
        return [...prev, key]
      })
    }

  const onClickMore = (e: MouseEvent, element: OptionTreeItem) => {
    e.preventDefault()
    e.stopPropagation()
    element.getNextPage?.()
  }

  const onMouseEnter = () => {
    if (onOpenTree && valueParent) {
      onOpenTree(valueParent)
    }
  }

  return (itemsTree || []).map((element, index) => {
    const isChecked =
      isSelectWithChildren &&
      (checked ||
        toArray(checkedValues).includes(element.value as string | number) ||
        toArray(checkedValues).some((item) => element.parentIds?.includes(Number(item))) ||
        (checkParentWithCheckedChildren && findCheckedAllChildren(element, checkedValues)))

    return (
      <Fragment key={element.value ?? index}>
        <ItemElement
          className={cx(`level${level}`, 'item-tree', {
            'items-checked': isChecked,
            'items-dark':
              findCheckedDeep(element, checkedValues) ||
              findCheckedDeepByParentIds(element, selectedParentIds, checkedValues, selectedOptions),
            'items-hide': level > 1 && !(valueParent && openTreeItems.includes(valueParent)) && !isSearch,
            'items-open': (element.value && openTreeItems.includes(element.value)) || isSearch,
            'table-item': !isSelectable,
            [`tree-level${level}`]: !isSelectable,
            isSearch,
          })}
          code={element.code}
          items={element.items}
          key={`${element.value ?? index}${level}`}
          value={element.value}
        >
          <span
            className={cx('items-arrow', {
              'items-opacity': !element.items?.length && !element?.childrenCount,
              'hide-arrow': isSearch,
              'items-arrow-no-checking': !multiple,
            })}
            onClick={
              (!!element.items?.length || !!element?.childrenCount) && !isSearch
                ? onToggle(element.value, element)
                : undefined
            }
          >
            <ArrowIcon />
          </span>
          {(!!element.items?.length || !!element?.childrenCount) && !isSearch ? (
            <span
              className={cx('option-text', { 'option-hover': multiple })}
              onClick={(event) => multiple && onToggle(element.value, element)(event)}
            >
              {element.label}
            </span>
          ) : (
            <div className="item-with-code">
              {!isSelectable && !!element.code && !element.items?.length && (
                <div className="item-code">{element.code}</div>
              )}
              <div>{element.label}</div>
            </div>
          )}
        </ItemElement>
        {}
        {!!element.items?.length &&
          itemsTreeParse({
            itemsTree: element.items,
            openTreeItems,
            setOpenTreeItems,
            isSearch,
            level: level + 1,
            valueParent: element.value,
            onOpenTree,
            checkedValues,
            selectedParentIds,
            isSelectable,
            multiple,
            selectedOptions,
            checked: isChecked,
            isSelectWithChildren,
            checkParentWithCheckedChildren,
          })}
        {itemsTree?.length === index + 1 &&
          element.hasNextPage &&
          ((valueParent && openTreeItems.includes(valueParent)) || !valueParent) && (
            <ItemElement
              className={cx(`level${level}`, 'rc-select-item-option-non-check', {
                [`tree-level${level}`]: !isSelectable,
              })}
              disabled
              key={level}
              value=""
            >
              <div
                className={cx({
                  'table-button-more-big': !isSelectable && isFirstLevel,
                  'table-button-more-small': !isSelectable && !isFirstLevel,
                })}
                onMouseEnter={onMouseEnter}
              >
                <Button
                  color={ButtonColors.Border}
                  onClick={(event) => onClickMore(event, element)}
                  size={ButtonSizes.Small}
                >
                  {t('showMore')}
                </Button>
              </div>
            </ItemElement>
          )}
      </Fragment>
    )
  })
}
