import { RefObject, useEffect } from 'react'

import { isEqual } from 'lodash-es'

export const useSizeEqual = (
  ref1: RefObject<HTMLElement>,
  ref2: RefObject<HTMLElement>,
  cb: (equal: boolean) => void,
  deps?: any[],
) => {
  useEffect(() => {
    if (!ref1?.current || !ref2.current) {
      return
    }

    const sizes: Record<number, { width: number; height: number }> = {}

    const onChange = () => {
      if (!sizes[0] || !sizes[1]) {
        return
      }
      cb(
        isEqual(sizes[0], sizes[1]) &&
          ref1.current?.getBoundingClientRect().width === ref2.current?.getBoundingClientRect().width,
      )
    }

    const resizeObserver1 = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = entry.contentRect.width || 0
        const height = entry.contentRect.height || 0
        if (!isEqual({ width, height }, sizes[0])) {
          sizes[0] = { width, height }
          queueMicrotask(onChange)
        }
      })
    })
    const resizeObserver2 = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = entry.contentRect.width || 0
        const height = entry.contentRect.height || 0
        if (!isEqual({ width, height }, sizes[1])) {
          sizes[1] = { width, height }
          queueMicrotask(onChange)
        }
      })
    })

    resizeObserver1.observe(ref1.current)
    resizeObserver2.observe(ref2.current)

    return () => {
      resizeObserver1.disconnect()
      resizeObserver2.disconnect()
    }
  }, [ref1.current, ref2.current, ...(deps || [])])
}
