import { GetAllData } from '@jume/api'

export interface PaginationResult<Item = unknown> {
  count: number
  next: number | null
  results: Item[]
  page?: number | null
  perPage?: number
}

export const createPaginationModel =
  <Item = unknown>(cb: (data: GetAllData<Item>) => PaginationResult<Item>) =>
  (data: GetAllData<Item> | undefined) =>
    data === undefined ? undefined : cb(data)

export const defaultPaginationModel = createPaginationModel((data) => ({
  count: data.count,
  next: data.next,
  results: data.results,
  page: data.page,
  perPage: data.perPage,
}))
