import { GetAllData } from '@jume/api'
import { AuthBuilder } from 'packages/core/auth'
import { AuthState } from 'packages/core/auth/types'
import { defaultPaginationModel, GlobalParameters, PaginationResult, StreamOptions } from 'packages/core/config'
import { MenuBuilder } from 'packages/core/menu'

export interface GlobalModulesState {
  streams: Record<string, StreamOptions>
  initialRequests: {
    run: (authBuilder: AuthBuilder) => Promise<void>
  }
  defineStartPage: (appState: Partial<GlobalContextProps<any>>) => string
  isValidStreamForCurrentUser: (appState: Partial<GlobalContextProps<any>>, streamName: string) => boolean
  session?: {
    clearCommonData?: () => void
  }
}

export interface GlobalInternalState<ModulesState extends GlobalModulesState = GlobalModulesState> {
  langInitiated: boolean
  auth: AuthState
  menuBuilder: MenuBuilder | null
  modules: ModulesState
  commonBottomMenu?: (menuBuilder: MenuBuilder) => void
}

export interface GlobalOptions<Item = unknown> {
  paginationModel: (data: GetAllData<Item> | undefined) => PaginationResult<Item> | undefined
  global: GlobalParameters
}

export type GlobalContextProps<
  ModulesState extends GlobalModulesState = GlobalModulesState,
  Item = unknown,
> = GlobalInternalState<ModulesState> & GlobalOptions<Item>

export const defaultGlobalContextValue: GlobalContextProps = {
  paginationModel: defaultPaginationModel,
  langInitiated: false,
  auth: {
    authReady: false,
    authenticated: false,
    setAuthenticated: async () => {},
    authenticate: async () => {},
    logout: {},
  },
  menuBuilder: null,
  modules: {
    streams: {},
    initialRequests: {
      run: async () => {},
    },
    defineStartPage: () => '/',
    isValidStreamForCurrentUser: () => false,
  },
  global: {},
}
