import { forwardRef, memo, MutableRefObject, useEffect, useMemo, useRef } from 'react'

import CanvasJSReact from '@canvasjs/react-stockcharts'
import { usePrevious, useSize } from 'ahooks'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { isEqual } from 'lodash-es'
import { uid } from 'uid'

import { CanvasJsChartOptions, ChartRef } from './interfaces'

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart

interface Props {
  className?: string
}

export const StockChart = memo(
  forwardRef<HTMLDivElement, { options: CanvasJsChartOptions; refChart?: MutableRefObject<ChartRef | null> } & Props>(
    ({ options, refChart, className }, ref) => {
      const refInstance = useRef<ChartRef | null>(null)
      const refWrap = useRef<HTMLDivElement>(null)
      const refPreviousId = useRef('')

      const size = useSize(refWrap)
      const previousWidth = usePrevious(size?.width)

      const { cbRef } = useCombinedRef(refInstance, refChart)
      const { cbRef: cbRefWrap } = useCombinedRef(refWrap, ref)

      const previousData = usePrevious(options.charts[0]?.data)

      useEffect(() => {
        if (size?.width && previousWidth && refInstance.current) {
          refInstance.current.render()
        }
      }, [size?.width])

      const generatedId = useMemo(() => {
        if (refInstance.current && previousData && options.rerender?.(previousData)) {
          refPreviousId.current = uid()
        }
        return refPreviousId.current
      }, [!!refInstance.current, options.charts[0]?.data, previousData])

      return (
        <div
          className={cx('canvasjs', className)}
          key={`${options.charts[0]?.data?.length}-${generatedId}`}
          ref={cbRefWrap}
        >
          <CanvasJSStockChart
            onRef={(instance: any) => {
              if (!instance?._axisXMin || !instance?._axisXMax) {
                return
              }

              const getPeriod = () => {
                const start: number = Math.min(
                  instance.charts[0]?.axisX[0]?.minimum,
                  instance.charts[0]?.axisX[0]?.dataInfo.min,
                )
                const end: number = Math.max(
                  instance.charts[0]?.axisX[0]?.maximum,
                  instance.charts[0]?.axisX[0]?.dataInfo.max,
                )
                return { start, end }
              }

              cbRef({
                initialRange: getPeriod(),
                updateInitialRange(period) {
                  if (!refInstance.current) {
                    return
                  }
                  const newPeriod = period ?? getPeriod()
                  refInstance.current.initialRange = newPeriod
                  if (refChart?.current) {
                    refChart.current.initialRange = newPeriod
                  }
                },
                resetZoom() {
                  if (!refInstance.current?.initialRange.start || !refInstance.current?.initialRange.end) {
                    return
                  }
                  instance.navigator.slider?.set('minimum', new Date(refInstance.current.initialRange.start))
                  instance.navigator.slider?.set('maximum', new Date(refInstance.current.initialRange.end + 1))

                  if (instance.rangeChanged) {
                    instance.rangeChanged({
                      minimum: refInstance.current.initialRange.start,
                      maximum: refInstance.current.initialRange.end,
                    })
                  }
                  if (instance.rangeChanging) {
                    instance.rangeChanging({
                      minimum: refInstance.current.initialRange.start,
                      maximum: refInstance.current.initialRange.end,
                    })
                  }
                },
                updateZoom(range) {
                  if (!range.start || !range.end || !instance.navigator.slider) {
                    return
                  }
                  instance.navigator.slider.set('minimum', new Date(range.start))
                  instance.navigator.slider.set('maximum', new Date(range.end))
                },
                visibilityLines: null,
                toggleVisibilityLines(visibilityLines) {
                  if (!refInstance.current) {
                    return
                  }
                  if (!refInstance.current?.visibilityLines) {
                    refInstance.current.visibilityLines = instance.charts[0]?.data.reduce(
                      (acc: Record<string, boolean>, dataSeries: any) => {
                        acc[dataSeries.name] = dataSeries.get('visible')
                        return acc
                      },
                      {},
                    )
                    if (refChart?.current) {
                      refChart.current.visibilityLines = refInstance.current.visibilityLines
                    }
                  }
                  const stateVisibilityLines = refInstance.current?.visibilityLines as Record<string, boolean>

                  const isNoLines = Object.keys(stateVisibilityLines).reduce((acc, line) => {
                    if (stateVisibilityLines[line]) {
                      return false
                    }
                    return acc
                  }, true)

                  const lines = Object.keys(visibilityLines)
                  lines.forEach((line, index) => {
                    instance.navigator?.data
                      ?.filter((item: any) => item.name === line)
                      .forEach((dataSeries: any) => {
                        dataSeries.set('visible', visibilityLines[line], false)
                      })
                    instance.charts[0]?.data
                      .filter((item: any) => item.name === line)
                      .forEach((dataSeries: any) => {
                        dataSeries.set('visible', visibilityLines[line], index === lines.length - 1)
                        stateVisibilityLines[line] = visibilityLines[line]
                      })
                  })

                  instance.navigator.slider?.set('minimum', new Date(instance.navigator.slider?.get('minimum')))
                  const { start, end } = getPeriod()
                  if (
                    isNoLines ||
                    refInstance.current?.initialRange.start !== start ||
                    refInstance.current?.initialRange.end !== end
                  ) {
                    const period = getPeriod()
                    refInstance.current?.updateInitialRange(period)
                    refInstance.current?.resetZoom()
                    if (refChart?.current) {
                      refChart.current.rangeToStart?.(period)
                    }
                  }
                },
                render: instance.render.bind(instance),
              })
            }}
            options={options}
          />
        </div>
      )
    },
  ),
  (prevProps, nextProps) => isEqual(prevProps.options, nextProps.options),
)
