import { useContext } from 'react'

import { getLang, setLang, t } from '@jume/localization'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { useProfile } from 'packages/app/modules/profile'
import { Project, projectsApi } from 'packages/app/modules/projects/api'
import { AppContext } from 'packages/app/providers'
import { ApiErrorsCodes } from 'packages/core'
import { getEffects } from 'packages/core/effects/getEffects'
import { useQueryClient } from 'packages/react-query'

import { ProjectsQueries } from './types'

export const useProject = () => {
  const queryClient = useQueryClient()
  const appContext = useContext(AppContext)
  const getProjectEffects = getEffects(appContext.modules.stream.currentStream?.projects?.onGetProject)
  const { profile } = useProfile()
  const params = useParams({ strict: false })
  const projectId = params.projectId ? Number(params.projectId) : profile?.activeProjectId
  const enabled = !!projectId

  const { data, isPending, error } = useQuery({
    queryKey: [ProjectsQueries.Project, projectId],
    queryFn: () => {
      if (projectId) {
        return projectsApi.getProject(projectId)
      }
      return null
    },
    enabled,
    meta: {
      error: (apiError) => ({
        message:
          apiError?.code === ApiErrorsCodes.NotFound
            ? t('projects.notFound', { projectId: String(projectId) })
            : t('errorsNoData.project'),
        showToast: false,
      }),
      onSuccess: async (project: Project) => {
        await setLang(project?.language.code ?? getLang())
        getProjectEffects.call(project)
        appContext.modules.project.setCurrentProject(project)
        queryClient.removeQueries({ queryKey: [ProjectsQueries.Project], type: 'inactive' })
      },
    },
  })

  return {
    project: data,
    isLoadingProject: isPending && enabled,
    error,
    isPoc: !!data?.pocDemand,
  }
}
