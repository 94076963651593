import { replace } from 'lodash-es'

export const antiAutoComplete = (label?: string) => {
  if (!label) {
    return label
  }
  let newLabel = label
  newLabel = replace(newLabel, 'Адрес', 'Адреc')
  newLabel = replace(newLabel, 'Город', 'Горoд')
  newLabel = replace(newLabel, 'город', 'горoд')
  newLabel = replace(newLabel, 'лицо', 'лицo')
  newLabel = replace(newLabel, 'дом', 'дoм')
  return newLabel
}
