import { RefObject, useEffect } from 'react'

import { isElement } from 'lodash-es'

interface ResizeObserverArgs {
  ref: RefObject<HTMLElement> | HTMLElement | undefined | null
  callback: (width: number, height: number) => void
  dependencies?: any[]
  enabled?: boolean
}

export const useResizeObserver = ({ ref, callback, dependencies, enabled = true }: ResizeObserverArgs) => {
  const element = isElement(ref) ? ref : (ref as any)?.current
  useEffect(() => {
    if (!element || !enabled) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const width = entry.contentRect.width || 0
        const height = entry.contentRect.height || 0
        callback(width, height)
      })
    })
    resizeObserver.observe(element)
    return () => {
      resizeObserver.disconnect()
    }
  }, [element, enabled, ...(dependencies || [])])
}
