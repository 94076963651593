import { ApiError, paramsSerializerToSnakeCaseArrayBrackets } from '@jume/api'
import { context, Exception, SpanStatusCode, trace } from '@opentelemetry/api'
import { AxiosError, AxiosResponse } from 'axios'
import { CustomAxiosRequestConfig } from 'packages/core/apiConfig'

import { defaultSpanOptions } from './defaultSpanOptions'
import { addHttpData } from './helpers/addHttpData'
import { getUserIdFormJWT } from './helpers/getUserIdFormJWT'
import { limitStringLength } from './helpers/limitStringLength'
import { parseResponseData } from './helpers/parseResponseData'
import { tracer } from './tracer'

export const tracingResponseInterceptor = (response: AxiosResponse | AxiosError, apiError?: ApiError) => {
  let userId = ''
  const config = response.config as CustomAxiosRequestConfig
  if (response.config.headers?.Authorization) {
    const token = String(response.config.headers.Authorization).replace('Bearer ', '')
    try {
      userId = String(getUserIdFormJWT(token))
    } catch (e) {}
  }

  const span = config.span
  if (span) {
    const resources = performance.getEntriesByType('resource') as unknown as PerformanceResourceTiming[]
    const queryParams = response.config.params
    const queryString = paramsSerializerToSnakeCaseArrayBrackets(queryParams)

    let baseURL = response.config.baseURL || ''
    if (baseURL?.endsWith('/')) {
      baseURL = baseURL.slice(0, -1)
    }

    const fullUrl = baseURL + response.config.url + (queryString ? '?' + queryString : '')
    const entry = resources.find((resource) => resource.name === fullUrl)

    performance.clearResourceTimings()

    if (entry) {
      const ctx = trace.setSpan(context.active(), span)

      const redirectSpan = tracer.startSpan('redirect', { startTime: entry.redirectStart, ...defaultSpanOptions }, ctx)
      addHttpData(redirectSpan, config)
      redirectSpan.end(entry.redirectEnd)

      const domainLookupSpan = tracer.startSpan(
        'domainLookup',
        { startTime: entry.domainLookupStart, ...defaultSpanOptions },
        ctx,
      )
      addHttpData(domainLookupSpan, config)
      domainLookupSpan.end(entry.domainLookupEnd)

      const connectSpan = tracer.startSpan(
        'connect',
        { startTime: entry.connectStart || entry.connectStart, ...defaultSpanOptions },
        ctx,
      )
      addHttpData(connectSpan, config)
      connectSpan.end(entry.connectEnd)

      const secureConnectionSpan = tracer.startSpan(
        'secureConnection',
        { startTime: entry.secureConnectionStart || entry.connectStart },
        ctx,
      )
      addHttpData(secureConnectionSpan, config)
      secureConnectionSpan.end(entry.connectEnd)

      const requestSpan = tracer.startSpan('request', { startTime: entry.requestStart, ...defaultSpanOptions }, ctx)
      addHttpData(requestSpan, config)
      requestSpan.end(entry.responseStart)

      const responseSpan = tracer.startSpan(
        'response',
        { startTime: entry.responseStart || entry.fetchStart, ...defaultSpanOptions },
        ctx,
      )
      addHttpData(responseSpan, config)
      responseSpan.setAttribute('response_status', String(response.status))
      responseSpan.setAttribute('transfer_size', String(entry.transferSize))
      responseSpan.end(entry.responseEnd)
    }

    span.setAttribute(
      'request_data',
      limitStringLength(
        typeof response.config.data === 'string'
          ? response.config.data
          : response.config.data instanceof FormData
            ? '[FormData]'
            : JSON.stringify(response.config.data),
      ),
    )
    span.setAttribute(
      'response_data',
      limitStringLength(
        parseResponseData((response as AxiosError)?.response?.data ?? (response as AxiosResponse).data),
      ),
    )
    span.setAttribute('user_id', userId)

    if (apiError) {
      span.recordException(apiError as Exception)
      span.setStatus({ code: SpanStatusCode.ERROR })
    } else {
      span.setStatus({ code: SpanStatusCode.OK })
    }
    span.end()
  }

  return Promise.reject(apiError ?? response)
}
