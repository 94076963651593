import { CSSProperties, forwardRef, HTMLAttributes, RefObject } from 'react'

import { useLatest } from 'ahooks'
import { isEqual } from 'lodash-es'
import {
  Draggable,
  placeholderBetweenStrategy,
  placeholderOnlyOtherListStrategy,
  placeholderReplaceStrategy,
  PlaceholderStrategy,
  placeholderTreeBottomLevelNotOrderStrategy,
  placeholderTreeBottomLevelStrategy,
} from 'packages/ui/Draggable'

import { useDraggableElementModify } from './hooks/useDraggableElementModify'
import classes from './Table.module.scss'
import { DraggableStrategy } from './types'

export const TBody = forwardRef<
  HTMLTableSectionElement,
  Omit<HTMLAttributes<HTMLTableSectionElement>, 'onChange'> & {
    isDraggable?: boolean
    draggableStrategy: DraggableStrategy
    draggableOnChange?: (oldIndex: number, newIndex: number, item: any | any[] | null, into: any | null) => void
    draggableGetDescription?: (meta: any | null) => string
    draggableIsGlobal?: boolean
    collapsingTreeKeys?: string[]
    draggableOnPaste?: (newIndex: number, item: any | any[], into: any) => void
    refScroll?: RefObject<HTMLDivElement>
    style?: CSSProperties | undefined
    selectableRowsForDragData?: Record<string | number, any>
  }
>(
  (
    {
      isDraggable,
      draggableStrategy,
      children,
      draggableOnChange,
      draggableGetDescription,
      draggableIsGlobal,
      collapsingTreeKeys,
      draggableOnPaste,
      refScroll,
      selectableRowsForDragData,
      ...props
    },
    ref,
  ) => {
    const placeholderStrategies: Record<DraggableStrategy, PlaceholderStrategy> = {
      rows: placeholderReplaceStrategy,
      rowsOnlyOtherList: placeholderOnlyOtherListStrategy,
      tree: placeholderBetweenStrategy,
      treeBottomLevel: placeholderTreeBottomLevelStrategy,
      treeBottomLevelNotOrder: placeholderTreeBottomLevelNotOrderStrategy,
    }

    const tbodyClass: Record<DraggableStrategy, string | undefined> = {
      rows: classes.placeholderReplaceStrategy,
      rowsOnlyOtherList: classes.placeholderOnlyOtherList,
      tree: classes.placeholderBetweenStrategy,
      treeBottomLevel: classes.placeholderBetweenStrategy,
      treeBottomLevelNotOrder: classes.placeholderBetweenStrategy,
    }

    const { onDragStart, onDragEnd } = useDraggableElementModify(draggableGetDescription)
    const selectableRows = useLatest(selectableRowsForDragData)

    return isDraggable ? (
      <Draggable
        as="tbody"
        className={tbodyClass[draggableStrategy]}
        classNameDragging={classes.dragging}
        draggableIsGlobal={draggableIsGlobal}
        enabled
        onChange={(oldIndex, newIndex, item, into) => {
          draggableOnChange?.(
            oldIndex,
            newIndex,
            isEqual(selectableRows.current, {}) || !selectableRows.current
              ? item
              : Object.values(selectableRows.current),
            into,
          )
        }}
        placeholderElement="tr"
        placeholderStrategy={placeholderStrategies[draggableStrategy]}
        ref={ref}
        {...props}
        draggableListsMeta={selectableRows}
        draggableOnPaste={(newIndex, item, into, draggableLists) => {
          const selectableRowsOtherTable = Object.values(draggableLists)
            .map((dragItem) => dragItem.meta?.current)
            .filter((rows) => !isEqual(rows, {}) && rows)[0]
          draggableOnPaste?.(newIndex, !selectableRowsOtherTable ? item : Object.values(selectableRowsOtherTable), into)
        }}
        maxLevel={collapsingTreeKeys?.length || 0}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        refWrap={refScroll}
      >
        {children}
      </Draggable>
    ) : (
      <tbody {...props} ref={ref}>
        {children}
      </tbody>
    )
  },
)
