import { useCallback, useContext, useEffect, useState } from 'react'

import { removeElementFromArray } from '@jume/utils'
import { VirtualScrollPosition } from 'interfaces/excelTable.interfaces'
import { max, min } from 'lodash-es'
import { ExcelTableContext } from 'packages/ui/ExcelTable/context/ExcelTableContext'

export const usePagesSlice = (
  getPerPage: () => number,
  getCountX: () => number,
  getCountY: () => number,
  getPaginateAxis: () => 'x' | 'y',
  setSlicePages: (slicePages: [number, number]) => void,
) => {
  const { virtualScroll, tableState } = useContext(ExcelTableContext)
  const overscan = 500

  const [slice, setSlice] = useState<[number, number]>([0, 0])

  const onChangeScroll = useCallback((position: VirtualScrollPosition) => {
    const perPage = getPerPage()
    const paginateAxis = getPaginateAxis()
    const count = paginateAxis === 'y' ? getCountY() : getCountX()
    const pagesCount = !!count && !!perPage ? Math.ceil(count / perPage) : 0

    if (!perPage) {
      return
    }

    if (paginateAxis === 'x') {
      const currentPages = Object.entries(tableState.currentSliceX)
        .filter(([_, sliceX]) => sliceX[0] > -1)
        .map(([page]) => Number(page) - 1)
      const newSlice: [number, number] = [min(currentPages) ?? -1, max(currentPages) ?? -1]
      setSlicePages(newSlice)
      return setSlice(newSlice)
    }

    let rowsSliceStart = Math.floor(((position.startY || 0) - overscan) / tableState.heightRow) - 1
    if (rowsSliceStart < 0) {
      rowsSliceStart = 0
    }
    let rowsSliceEnd = Math.ceil(((position.endY || 0) + overscan) / tableState.heightRow) - 1
    if (rowsSliceEnd > pagesCount * perPage - 1) {
      rowsSliceEnd = pagesCount * perPage - 1
    }
    const sliceStart = Math.floor(rowsSliceStart / perPage)
    const sliceEnd = Math.ceil((rowsSliceEnd + 1) / perPage) - 1
    const newSlice: [number, number] = [sliceStart, sliceEnd]
    setSlicePages(newSlice)
    setSlice(newSlice)
  }, [])

  useEffect(() => {
    onChangeScroll(virtualScroll.position)
    virtualScroll.onChangeFns.push(onChangeScroll)
    return () => removeElementFromArray(virtualScroll.onChangeFns, onChangeScroll)
  }, [onChangeScroll])

  return { slice }
}
