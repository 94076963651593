import { ApiError, ApiTypingErrors } from '@jume/api'
import { t } from '@jume/localization'

import { ApiErrorServer } from './types'

export enum ApiErrorsCodes {
  NotFound = 'not_found',
  NotValid = 'not_valid',
  UnknownError = 'unknown_error',
}

export const apiTypingErrors = new ApiTypingErrors<ApiError, ApiErrorServer>({
  notValidStatus: 400,
  initialError: () => ({
    message: t('apiErrors.unknownError'),
    code: ApiErrorsCodes.UnknownError,
  }),
  notFoundError: () => ({
    message: t('apiErrors.notFound'),
    code: ApiErrorsCodes.NotFound,
  }),
  notValidError: () => ({
    message: t('apiErrors.notValid'),
    code: ApiErrorsCodes.NotValid,
  }),
  validationReplaceToCamelCase: true,
})

export const { errorInterceptor, createApiError } = apiTypingErrors
