import { useChangedCells as getStateChangedCells } from 'demand/forecast/store'
import { ExcelStateSelectedCells } from 'interfaces/excelTable.interfaces'
import { isEqual } from 'lodash-es'
import { devtools } from 'packages/core'
import { defaultSelectedCells } from 'packages/ui/ExcelTable/constants/selectedCells'
import { avgValuesCells } from 'packages/ui/ExcelTable/helpers/avgValuesCells'
import { getCellsMatrix } from 'packages/ui/ExcelTable/helpers/getCellsMatrix'
import { sumValuesCells } from 'packages/ui/ExcelTable/helpers/sumValuesCells'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export const useSelectedCells = create<ExcelStateSelectedCells>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        selectedCells: defaultSelectedCells,
        sumSelectedCells: 0,
        avgSelectedCells: 0,
        clearSelectedCells: () =>
          set({
            selectedCells: defaultSelectedCells,
            sumSelectedCells: 0,
            avgSelectedCells: 0,
          }),
        setSelectedCells: (selectedCells, state) =>
          set(
            (prev) => {
              if (isEqual(selectedCells, prev.selectedCells)) {
                return prev
              }
              const changedCells = getStateChangedCells.getState().changedCells
              const cells = getCellsMatrix(state, selectedCells, changedCells)
              const sumSelectedCells = sumValuesCells(cells)
              const avgSelectedCells = avgValuesCells(sumSelectedCells, cells)

              return {
                selectedCells,
                sumSelectedCells,
                avgSelectedCells,
              }
            },
            undefined,
            'setSelectedCells',
          ),
      }),
      {
        store: 'forecastTableSelectedCells',
      },
    ),
  ),
)
