import { GetAllData } from '@jume/api'
import { InnovationTableRow } from 'jume/innovationTable/api'
import { PaginateData } from 'packages/react-query'

export enum InnovationTabsCodes {
  All = 'all',
  Regular = 'regular',
  Archive = 'archive',
}

export type InnovationAllData = Record<InnovationTabsCodes, PaginateData<GetAllData<InnovationTableRow>> | undefined>
