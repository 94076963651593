import { FC, Suspense } from 'react'

import { t } from '@jume/localization'
import { lazyRouteComponent } from '@tanstack/react-router'
import { useTitle } from 'packages/app'
import { Loader, LoaderTypes } from 'packages/ui'

const components = new Map<string, FC>()

const lazyComponent = (fn: () => Promise<Record<string, FC>>, name: string, props?: Record<string, any>) => {
  if (!name) {
    return null
  }
  if (components.has(name)) {
    const ComponentFromCash = components.get(name) as FC
    return <ComponentFromCash {...props} />
  }
  const Component = lazyRouteComponent(fn, name)
  components.set(name, Component)
  return <Component {...props} />
}

export const createPage =
  (params: {
    titleKey?: Parameters<typeof t>[0]
    lazyImport?: {
      fn: () => Promise<Record<string, FC>>
      name: string
      props?: Record<string, any>
    }
    Component?: FC
  }) =>
  () => {
    const { titleKey, lazyImport, Component } = params
    useTitle(titleKey || 'titles.default')

    return (
      <Suspense fallback={<Loader type={LoaderTypes.SpinnerCenter} />}>
        {Component ? (
          <Component {...lazyImport?.props} />
        ) : lazyImport ? (
          lazyComponent(lazyImport.fn, lazyImport.name, lazyImport.props)
        ) : null}
      </Suspense>
    )
  }
