import { forwardRef, ReactElement, ReactNode, Ref, useEffect, useRef, useState } from 'react'

import { ApiError, getErrorMessage } from '@jume/api'
import { t } from '@jume/localization'
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual'
import { useDeepCompareEffect, useInfiniteScroll } from 'ahooks'
import DeleteIcon from 'assets/images/close-16.svg?react'
import MoveElementIcon from 'assets/images/move-element.svg?react'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { Checkbox } from 'packages/ui/Checkbox'
import { DragElement, Draggable, DraggableItem } from 'packages/ui/Draggable'
import { Loader, LoaderColors, LoaderTypes } from 'packages/ui/Loader'
import { MarkItem, MarkItemTypes } from 'packages/ui/MarkItem'

import classes from './List.module.scss'

export interface ListItem<ValueType = string | number | null, Meta = undefined> {
  label?: ReactNode
  value: ValueType
  meta?: Meta
  className?: string
  key?: string | number
  loading?: boolean
}

interface ListProps<ValueType = string | number | null, Meta = undefined> {
  className?: string
  emptyTextClassName?: string
  data?: ListItem<ValueType, Meta>[]
  draggable?: boolean
  deletable?: boolean
  selectable?: boolean
  selectedValues?: ValueType[]
  setSelectedValues?: (values: ValueType[]) => void
  onChangeSelect?: (value: ValueType, checked: boolean, item: ListItem<ValueType, Meta>, index: number) => void
  ignoreNotSelect?: boolean
  onChangeOrder?: (order: ValueType[]) => void
  onRemove?: (value: ValueType) => void
  isLoading?: boolean
  scrollable?: boolean
  loadingOnScroll?: boolean
  onScrollBottom?: () => void
  emptyText?: ReactNode
  noPadding?: boolean
  lastBorder?: boolean
  error?: ApiError | null
  virtualize?: boolean
}

const InternalList = <ValueType = string | number | null, Meta = undefined>(
  {
    className,
    emptyTextClassName,
    data = [],
    draggable,
    deletable,
    selectable,
    selectedValues,
    setSelectedValues,
    onChangeSelect,
    ignoreNotSelect,
    onChangeOrder,
    onRemove,
    isLoading,
    scrollable,
    loadingOnScroll,
    onScrollBottom,
    emptyText,
    noPadding,
    lastBorder,
    error,
    virtualize = !draggable,
  }: ListProps<ValueType, Meta>,
  ref?: Ref<HTMLDivElement>,
) => {
  const refValues = useRef<ValueType[]>(data.map((item) => item.value) || [])
  const [dataInternal, setDataInternal] = useState<typeof data>(data || [])

  const refScroll = useRef<HTMLDivElement>(null)
  const refContent = useRef<HTMLDivElement>(null)
  const { cbRef } = useCombinedRef<HTMLDivElement>(ref, refScroll)
  const threshold = 150

  const { loadMore } = useInfiniteScroll((onScrollBottom as any) || (() => {}), {
    target: refScroll,
    threshold,
    manual: !scrollable,
  })

  useEffect(() => {
    if (
      scrollable &&
      refScroll.current &&
      refContent.current &&
      refScroll.current.offsetHeight > refContent.current.clientHeight
    ) {
      loadMore()
    }
  }, [scrollable, refScroll.current, refContent.current?.clientHeight])

  const moveElementInArray = (oldIndex: number, newIndex: number) => {
    const arr = [...refValues.current]
    const elementToMove = arr.splice(oldIndex, 1)[0]
    arr.splice(newIndex, 0, elementToMove)
    let order = arr
    if (ignoreNotSelect) {
      order = order.filter((item) => selectedValues?.includes(item))
    }
    requestAnimationFrame(() => onChangeOrder?.(order))
    setDataInternal((prevData) => {
      const dataCopy = prevData.filter((item) => order.find((value) => value === item.value))
      dataCopy.sort((a, b) => {
        const indexA = order.indexOf(a.value)
        const indexB = order.indexOf(b.value)
        return indexA - indexB
      })
      return dataCopy
    })
    refValues.current = order
  }

  const onChangeSelectInternal = (
    valueItem: ValueType,
    checked: boolean,
    item: ListItem<ValueType, Meta>,
    index: number,
  ) => {
    onChangeSelect?.(valueItem, checked, item, index)
    if (checked) {
      setSelectedValues?.([...(selectedValues || []), valueItem])
    } else {
      setSelectedValues?.((selectedValues || []).filter((selectValue) => selectValue !== valueItem))
    }
  }

  useDeepCompareEffect(() => {
    refValues.current = data.map((item) => item.value) || []
    setDataInternal(data)
  }, [data])

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => refScroll.current,
    count: dataInternal.length,
    overscan: 10,
    estimateSize: () => 33,
    measureElement: (element: HTMLDivElement) => element.offsetHeight,
  })

  if (isLoading) {
    return (
      <div className={classes.loadCont}>
        <Loader color={LoaderColors.Gray} type={LoaderTypes.Spinner} />
      </div>
    )
  }

  return (
    <div className={cx(classes.wrap, className, { [classes.noPadding]: noPadding, [classes.lastBorder]: lastBorder })}>
      {error && <MarkItem type={MarkItemTypes.Danger}>{getErrorMessage(error)}</MarkItem>}

      {!error && !dataInternal?.length && (
        <div className={cx(classes.emptyText, emptyTextClassName)}>{emptyText ? emptyText : t('noData')}</div>
      )}

      {!error && (
        <div
          className={cx({
            'scroll scroll-mini': scrollable,
            [classes.scroll]: scrollable,
            [classes.virtualize]: virtualize,
          })}
          ref={cbRef}
          role="scroll"
        >
          <Draggable
            className={classes.cont}
            classNameDragging={classes.dragging}
            classNamePlaceholder={classes.placeholder}
            draggableIsGlobal
            enabled={draggable}
            onChange={moveElementInArray}
            ref={refContent}
            role="list"
            style={
              virtualize
                ? {
                    height: `${rowVirtualizer.getTotalSize()}px`,
                  }
                : undefined
            }
          >
            {(virtualize ? rowVirtualizer.getVirtualItems() : dataInternal).map((virtualRow, index) => {
              const item = virtualize
                ? dataInternal[(virtualRow as VirtualItem).index]
                : (virtualRow as ListItem<ValueType, Meta>)

              return (
                <DraggableItem
                  className={classes.item}
                  index={(virtualRow as VirtualItem).index}
                  isDragElement={false}
                  key={item.key || String(item.value)}
                  {...(virtualize
                    ? {
                        ['data-index']: (virtualRow as VirtualItem).index,
                        ref: rowVirtualizer.measureElement,
                        style: { top: (virtualRow as VirtualItem).start },
                      }
                    : {})}
                >
                  {draggable && (
                    <DragElement className={classes.moveElement} enabled={draggable}>
                      <MoveElementIcon />
                    </DragElement>
                  )}
                  <div
                    className={cx(classes.label, {
                      [classes.withDrag]: draggable,
                      [classes.withSelect]: selectable,
                      [classes.withDelete]: deletable,
                    })}
                    onClick={() =>
                      onChangeSelectInternal(item.value, !selectedValues?.includes(item.value), item, index)
                    }
                  >
                    {selectable && (
                      <div>
                        <Checkbox loading={item.loading} value={selectedValues?.includes(item.value)} />
                      </div>
                    )}
                    {item.label ?? String(item.value)}
                  </div>
                  {deletable && (
                    <div className={classes.delete} onClick={() => onRemove?.(item.value)}>
                      <DeleteIcon />
                    </div>
                  )}
                </DraggableItem>
              )
            })}
          </Draggable>
          <Loader show={loadingOnScroll} type={LoaderTypes.Paginate} />
        </div>
      )}
    </div>
  )
}

export const List = forwardRef(InternalList) as <ValueType = string | number | null, Meta = undefined>(
  props: ListProps<ValueType, Meta>,
) => ReactElement
