import { createElement } from 'react'

import cx from 'clsx'
import { findLastIndex } from 'lodash-es'
import classes from 'packages/ui/Draggable/Draggable.module.scss'
import { PlaceholderStrategy } from 'packages/ui/Draggable/types'

export const placeholderOnlyOtherListStrategy: PlaceholderStrategy = ({
  childrenList,
  placeholderElement,
  draggableIndex,
  classNamePlaceholder,
  refInitialIndex,
  refRectItem,
  refPlaceholder,
  refDraggableItem,
  refElementPosition,
  refPositions,
  refRealDraggableIndex,
  refList,
  refWrap,
  refIsOtherList,
}) => {
  if (refInitialIndex.current !== null && refRectItem.current && !refIsOtherList.current) {
    childrenList.splice(
      refInitialIndex.current,
      0,
      createElement(placeholderElement, {
        className: cx(classes.placeholder, classNamePlaceholder),
        'data-list-element': true,
        'data-placeholder-element': true,
        'data-initial-draggable-index': refInitialIndex.current,
        'data-draggable-index': draggableIndex,
        key: 'placeholder',
        style: { width: refRectItem.current.width, height: refRectItem.current.height },
        ref: refPlaceholder,
      }),
    )
  }

  if (refList.current && draggableIndex !== null) {
    childrenList.push(
      createElement(placeholderElement, {
        className: classes.levelWrapper,
        'data-level-wrapper-element': true,
        'data-initial-draggable-index': refInitialIndex.current,
        'data-draggable-index': draggableIndex,
        key: 'levelWrapper',
        style: {
          top: 1,
          bottom: 0,
          left: 0,
          right: 0,
        },
      }),
    )
  }

  const calculateIndex = (event: MouseEvent) => {
    if (!refDraggableItem.current || !refPositions.current || !refList.current) {
      return null
    }

    const windowX = event.clientX
    const windowY = event.clientY

    const wrapRect = refWrap?.current?.getBoundingClientRect()
    if (
      wrapRect &&
      !(windowX <= wrapRect.right && windowX >= wrapRect.left && windowY >= wrapRect.top && windowY <= wrapRect.bottom)
    ) {
      refRealDraggableIndex.current = null
      return null
    }

    const listTop = refList.current.getBoundingClientRect().top
    const top = refDraggableItem.current.getBoundingClientRect().top - listTop
    const positionItem = top + (refRectItem.current?.height || 0) / 2
    const isUp = refElementPosition.current ? refElementPosition.current.startPosition > top : false
    let index = isUp
      ? refPositions.current.findIndex((item) => item > positionItem)
      : findLastIndex(refPositions.current, (item) => item < positionItem)
    if (index === -1) {
      index = isUp ? refPositions.current.length : 0
    }
    refRealDraggableIndex.current = index + (isUp ? -1 : 0)
    return index + (isUp ? -1 : 1)
  }

  return {
    childrenList,
    calculateIndex,
  }
}
