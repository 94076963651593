import { FC, PropsWithChildren, useContext } from 'react'

import { t } from '@jume/localization'
import cx from 'clsx'
import { useGlobalMouseMove } from 'packages/app/hooks'
import { useOpenMenu } from 'packages/app/layout/store/useOpenMenu'
import { useLogout } from 'packages/app/modules/logout'
import { SessionOver, useSaveSessionPage } from 'packages/app/modules/session'
import { UpdatingSystem } from 'packages/app/modules/systemUpdating'
import { AppContext } from 'packages/app/providers'
import { UnsavedChangesModal } from 'packages/core'
import { ButtonEasyError, Link } from 'packages/ui'

import classes from './MainLayout.module.scss'
import { Sidebar } from './Sidebar'

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const appContext = useContext(AppContext)
  const currentStream = appContext.modules?.stream.currentStream
  const profile = appContext.modules.profile
  const { open } = useOpenMenu()
  const { logout } = useLogout()
  useSaveSessionPage()
  useGlobalMouseMove()

  if (profile && !profile.streams?.length && !currentStream) {
    return (
      <>
        <ButtonEasyError isClickable={false}>{t('noRole')}</ButtonEasyError>
        <div className={classes.linkExitCont}>
          <Link className={classes.linkExit} onClick={logout}>
            {t('auth.logout')}
          </Link>
        </div>
      </>
    )
  }

  return (
    <section className={cx(classes.wrap, { [classes.open]: open })} data-container="true">
      <Sidebar />
      <main className={classes.main}>
        <div className={classes.content}>{children}</div>
        {currentStream?.session?.enabled && <SessionOver />}
      </main>
      <UpdatingSystem />
      <UnsavedChangesModal />
    </section>
  )
}
