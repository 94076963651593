import { getError } from '@jume/api'
import { t } from '@jume/localization'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import { differenceInSeconds } from 'date-fns'
import { timeBeforeStartingJob } from 'demand/jobLock/constants'
import { jobLockChannel, typeJobLockStatus } from 'demand/jobLock/constants/ws'
import { useJobLockStatus } from 'demand/jobLock/queries'
import { DemandProvider } from 'demand/shared/provider/DemandProvider'
import { useModal } from 'hooks/useModal'
import { useWs } from 'packages/app'
import { Tag, TagColors, TextModal } from 'packages/ui'
import { shortedToday } from 'utils/date'

export const Route = createFileRoute('/demand')({
  component: DemandLayout,
})

function DemandLayout() {
  const { jobLock, error, setJobLockStatus } = useJobLockStatus()
  const diffSeconds = jobLock?.realignment?.jobStartedAt
    ? differenceInSeconds(new Date(jobLock?.realignment?.jobStartedAt), new Date())
    : undefined

  const [isOpenJobModal, openJobModal, closeJobModal] = useModal()

  useWs(jobLockChannel, { onPublish: { [typeJobLockStatus]: setJobLockStatus } })

  return (
    <DemandProvider
      locked={{
        isLocked: Boolean(
          jobLock?.realignment?.jobIsRunning &&
            jobLock?.realignment?.jobStartedAt &&
            diffSeconds !== undefined &&
            diffSeconds <= 0,
        ),
        Component: error ? (
          <div className="mb12">
            <Tag color={TagColors.Danger} isClickable onClick={openJobModal}>
              {t('demand.jobLock.error')}
            </Tag>
          </div>
        ) : jobLock?.realignment?.jobIsRunning &&
          jobLock?.realignment?.jobStartedAt &&
          diffSeconds !== undefined &&
          diffSeconds <= timeBeforeStartingJob ? (
          <div className="mb12">
            <Tag color={TagColors.Danger} isClickable onClick={openJobModal}>
              {diffSeconds > 0
                ? t('demand.jobLock.jobWillBeStarted', {
                    time: shortedToday(jobLock?.realignment?.jobStartedAt),
                  })
                : t('demand.jobLock.jobIsAtWork')}
            </Tag>
          </div>
        ) : null,
        pages: {
          realignment: true,
          forecast: true,
          forecastDataUpload: true,
        },
      }}
    >
      <Outlet />

      {!!error && (
        <TextModal isOpened={isOpenJobModal} onClose={closeJobModal} title={t('demand.jobLock.error')}>
          {getError(error)}
        </TextModal>
      )}

      {!error && !!jobLock?.realignment && (
        <TextModal isOpened={isOpenJobModal} onClose={closeJobModal} title={t('demand.jobLock.jobIsAtWork')}>
          {diffSeconds !== undefined && diffSeconds > 0
            ? t('demand.jobLock.jobWillBeStartedTitle', {
                jobId: jobLock.realignment.jobId,
                jobName: jobLock.realignment.jobName,
                time: shortedToday(jobLock?.realignment?.jobStartedAt),
              })
            : t('demand.jobLock.jobIsAtWorkTitle', {
                jobId: jobLock.realignment.jobId,
                jobName: jobLock.realignment.jobName,
              })}
        </TextModal>
      )}
    </DemandProvider>
  )
}
