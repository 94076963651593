import { ExcelAxisItem, ExcelState, Subscribe, SubscribeColumnCell, TableState } from 'interfaces/excelTable.interfaces'
import { last } from 'lodash-es'

const generateSubscribeCell =
  <State extends ExcelState = ExcelState>(
    subscribe: Subscribe<State, any> | undefined,
    page: number,
    index: number,
    indexColumn: number,
  ) =>
  (listener: (selectedState: any, previousSelectedState: any) => void) =>
    subscribe?.(({ data }) => data?.[page].columns[index][indexColumn], listener)

const generateSubscribeWidth =
  <State extends ExcelState = ExcelState>(
    subscribe: Subscribe<State, any> | undefined,
    index: number,
    indexColumn: number,
    keys: string[][],
  ) =>
  (listener: (selectedState: any, previousSelectedState: any) => void) =>
    subscribe?.(({ widthColumns }) => widthColumns.widths?.[keys[index][indexColumn]], listener)

const generateSubscribeMinimalWidth =
  <State extends ExcelState = ExcelState>(
    subscribe: Subscribe<State, any> | undefined,
    index: number,
    indexColumn: number,
    keys: string[][],
  ) =>
  (listener: (selectedState: any, previousSelectedState: any) => void) =>
    subscribe?.(({ widthColumns }) => widthColumns.minimalWidths?.[keys[index][indexColumn]], listener)

const updateKey = (
  keys: string[][],
  index: number,
  indexColumn: number,
  columnCell: ExcelAxisItem,
  page: number,
  isNotCell: boolean,
  keysCash: Record<string, number>,
) => {
  let key = keys[index][indexColumn - 1]
    ? keys[index][indexColumn - 1].replace(/_\(\d+\)$/, '') + `_${columnCell.value}`
    : `${page}_${String(columnCell.value)}`
  if (isNotCell) {
    key += `_(${keysCash[key] || 0})`
  } else {
    keysCash[key] = (keysCash[key] || 0) + 1
    key += `_(${keysCash[key]})`
  }
  keys[index][indexColumn] = key
}

const generateCell = <State extends ExcelState = ExcelState>(
  subscribe: Subscribe<State, any> | undefined,
  getState: (() => State) | undefined,
  page: number,
  perPage: number | undefined,
  paginateAxis: 'x' | 'y',
  index: number,
  indexColumn: number,
  keys: string[][],
  key: string,
  columnCell: ExcelAxisItem,
  tableState: TableState,
): SubscribeColumnCell => {
  if (!tableState.columnsKeys[page]) {
    tableState.columnsKeys[page] = []
  }
  if (!tableState.columnsKeys[page][indexColumn]) {
    tableState.columnsKeys[page][indexColumn] = {}
  }
  tableState.columnsKeys[page][indexColumn][index] = key

  return {
    subscribeCell: generateSubscribeCell(subscribe, page, index, indexColumn),
    subscribeWidth: generateSubscribeWidth(subscribe, index, indexColumn, keys),
    subscribeMinimalWidth: generateSubscribeMinimalWidth(subscribe, index, indexColumn, keys),
    key,
    index: index + (paginateAxis === 'x' ? (page - 1) * (perPage || 0) : 0),
    lastIndex: index,
    item: columnCell,
    type: 'cells',
    isLastIndex: getState?.().countX === index + 1,
    getPosition: () => tableState.columns[key]?.position || { top: 0, left: 0 },
    isCalculated: () => !!tableState.columns[key]?.isCalculated,
    setCalculated: () => tableState.columns[key]?.setCalculated(),
    isMounted: () => !!tableState.columns[key]?.isMounted,
    setIsMounted: (isMounted) => tableState.columns[key]?.setIsMounted(isMounted),
    recalculateLeft: () => tableState.columns[key]?.recalculateLeft(),
    getWidth: () => getState?.().widthColumns.widths?.[key] || null,
  }
}

export const parseColumns = <State extends ExcelState = ExcelState>(
  columnsState: ExcelAxisItem[][] | undefined,
  subscribe: Subscribe<State, any> | undefined,
  getState: (() => State) | undefined,
  page: number,
  perPage: number | undefined,
  paginateAxis: 'x' | 'y',
  addKeyToCells: (page: number, keys: string[]) => void,
  tableState: TableState,
): { maxDepth: number; headerCells: (SubscribeColumnCell | null)[][] } => {
  let maxDepth = 0
  if (!columnsState) {
    return {
      maxDepth,
      headerCells: [],
    }
  }

  const keys: string[][] = []
  const keysCash: Record<string, number> = {}

  const headerCells = columnsState.reduce((newArray: (SubscribeColumnCell | null)[][], item, index, array) => {
    if (index === 0) {
      maxDepth = item.length
      newArray.push(
        item.map((columnCell, indexColumn) => {
          if (!keys[index]) {
            keys[index] = []
          }
          updateKey(keys, index, indexColumn, columnCell, page, false, keysCash)
          const key = keys[index][indexColumn]
          tableState.slices[key] = [index, index]
          return generateCell(
            subscribe,
            getState,
            page,
            perPage,
            paginateAxis,
            index,
            indexColumn,
            keys,
            key,
            columnCell,
            tableState,
          )
        }),
      )
    } else {
      if (maxDepth < item.length) {
        maxDepth = item.length
      }
      let isNotCell: boolean | undefined
      item.forEach((columnCell, indexColumn) => {
        let prevIndex = 1
        if (newArray[index - prevIndex]?.[indexColumn] === undefined) {
          return
        }
        while (newArray[index - prevIndex][indexColumn] === null) {
          prevIndex++
        }
        if (!newArray[index]) {
          newArray[index] = []
        }
        if (!keys[index]) {
          keys[index] = []
        }

        isNotCell = isNotCell !== false && array[index - prevIndex][indexColumn]?.value === columnCell.value

        updateKey(keys, index, indexColumn, columnCell, page, isNotCell, keysCash)
        const key = keys[index][indexColumn]

        const prevCell = newArray[index - prevIndex][indexColumn]

        if (isNotCell && prevCell) {
          prevCell.lastIndex = index
          tableState.slices[key][1] = index
        }
        if (!isNotCell) {
          tableState.slices[key] = [index, index]
        }

        newArray[index][indexColumn] = isNotCell
          ? null
          : generateCell(
              subscribe,
              getState,
              page,
              perPage,
              paginateAxis,
              index,
              indexColumn,
              keys,
              key,
              columnCell,
              tableState,
            )
      })
    }
    return newArray
  }, [])

  addKeyToCells(page, keys.map((array) => array[array.length - 1]).flat())

  tableState.getKeyCellByIndex = (indexKey) => last(keys[indexKey])

  return {
    maxDepth,
    headerCells,
  }
}
