import { CSSProperties, FC, ReactNode } from 'react'

import { t } from '@jume/localization'
import cx from 'clsx'

import classes from './Loader.module.scss'
import { LoaderColors, LoaderTypes } from './types'

interface LoaderProps {
  className?: string
  classNameCont?: string
  classNameWheel?: string
  type?: LoaderTypes
  color?: LoaderColors
  text?: ReactNode | true
  isBlock?: boolean
  style?: CSSProperties
  show?: boolean
}

export const Loader: FC<LoaderProps> = ({
  className,
  classNameCont,
  classNameWheel,
  type = LoaderTypes.Default,
  color = type === LoaderTypes.Paginate ? LoaderColors.Gray : LoaderColors.Default,
  text,
  isBlock,
  style,
  show,
  ...props
}) => {
  if (type === LoaderTypes.Text) {
    return <div className={cx(classes.text, className)}>{text || t('load')}</div>
  }

  return (
    <div
      className={cx(classes.loaderCont, className, {
        [classes.isBlock]: isBlock,
        full: type === LoaderTypes.SpinnerCenter || type === LoaderTypes.SpinnerCenterLarge,
        [classes.fullLarge]: type === LoaderTypes.SpinnerCenterLarge,
        [classes.paginateCont]: type === LoaderTypes.Paginate,
        [classes.show]: show,
      })}
      style={style}
      {...props}
    >
      {type === LoaderTypes.Spinner ||
      type === LoaderTypes.SpinnerMini ||
      type === LoaderTypes.SpinnerLarge ||
      type === LoaderTypes.SpinnerCenter ||
      type === LoaderTypes.SpinnerCenterLarge ? (
        <div className={cx(classes[type], classes[color])}>
          <div className={cx(classes.cont, classNameCont)}>
            <div className={cx(classes.wheel, classNameWheel)} />
          </div>
        </div>
      ) : (
        <div
          className={cx(
            classes.loader,
            classes[type === LoaderTypes.Paginate ? LoaderTypes.Default : type],
            classes[color],
          )}
          key={type}
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {text && <span className={classes.loaderText}>{text === true ? t('loadingData') : text}</span>}
    </div>
  )
}
