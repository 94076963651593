import { uniqBy } from 'lodash-es'
import { ChartContentFormatter } from 'packages/ui/Chart'

export const tooltipContent = (): ChartContentFormatter => (event) => {
  let str = ''

  str = str.concat(
    '<div class="chart-tooltip-date" style="margin: 0 0 5px 12px">' + event.entries[0].dataPoint.label + '</div>',
  )

  uniqBy(event.entries, (line) => line.dataSeries.name).forEach((item) => {
    if (item.dataPoint.markerType === 'none') {
      return
    }

    const line = `
        <div class="chart-tooltip-item">
          <div class="chart-tooltip-circle" style="background-color: ${item.dataSeries.color}"></div>
          <div>
            ${item.dataSeries.name} - <strong>${item.dataPoint.tooltipY}</strong>
          </div>
        </div>`

    str = str.concat(line)
  })

  return str
}
