import { CellFields, ExcelCellData, ExcelState, Subscribe, SubscribeCell } from 'interfaces/excelTable.interfaces'
import { clone, last } from 'lodash-es'

import { getOffsetCell } from './getOffsetCell'

export const parseCells = <State extends ExcelState = ExcelState>(
  cells: ExcelCellData[][] | undefined,
  subscribe: Subscribe<State, any> | undefined,
  getState: (() => State) | undefined,
  page: number,
  perPage: number,
  countX: number,
  countY: number,
  paginateAxis: 'x' | 'y',
): { cellsWithSubscriber: SubscribeCell[][] } => {
  if (!page) {
    return { cellsWithSubscriber: [] }
  }

  if (!cells) {
    const count = paginateAxis === 'y' ? countY : countX
    const lastPage = Math.ceil(count / perPage)
    const lastPageCount = count - (page - 1) * perPage
    cells = Array.from({ length: lastPage === page ? lastPageCount : perPage }).map(() =>
      Array.from({ length: countX }),
    )
  }

  const cellsWithSubscriber = cells.reduce((newArray: SubscribeCell[][], row, indexRow) => {
    newArray.push(
      row.map((cell, indexColumn) => {
        if (!cell) {
          cell = {} as any
        }
        cell.key =
          (cell?.key ?? paginateAxis === 'y') ? getState?.().data?.[1]?.cells[0]?.[indexColumn]?.key : undefined
        return {
          subscribeCell: (
            listener: (selectedState: any, previousSelectedState: any) => void,
            getLastUpdateDate?: () => Date,
            getInputValue?: () => number | null,
          ) =>
            subscribe?.(({ data }) => {
              const newCell = data?.[page]?.cells[indexRow]?.[indexColumn]
              if (newCell) {
                newCell.getColumn = () => last(data?.[page]?.columns[indexColumn])
                newCell.isLastRow =
                  (paginateAxis === 'y' ? Math.ceil(countY / perPage) === page : true) &&
                  data?.[page]?.cells.length === indexRow + 1
              }
              if (
                newCell?.lastUpdateDate &&
                getInputValue &&
                getLastUpdateDate &&
                getInputValue() !== newCell.value &&
                getLastUpdateDate().getTime() < newCell.lastUpdateDate.getTime()
              ) {
                return clone(newCell)
              }
              return newCell
            }, listener),
          subscribeWidth: (listener: (selectedState: any, previousSelectedState: any) => void) =>
            subscribe?.(({ data, widthColumns }) => {
              const cellData = data?.[page]?.cells[0]?.[indexColumn]
              const key = cellData?.key ?? data?.[1]?.cells[0]?.[indexColumn]?.key
              return key ? widthColumns.widths?.[key] : undefined
            }, listener),
          getWidth: () => {
            const state = getState?.()
            const cellData = state?.data?.[page]?.cells[0]?.[indexColumn]
            const key = cellData?.key ?? state?.data?.[1]?.cells[0]?.[indexColumn]?.key
            return key ? state?.widthColumns.widths?.[key] || null : null
          },
          getFields: () => {
            const state = getState?.()
            const rows = state?.data?.[page]?.rows[indexRow]
            const columns = state?.data?.[page]?.columns[indexColumn]
            const fields: CellFields = {}

            rows?.forEach(({ code, value }) => (fields[code] = value))
            columns?.forEach(({ code, value }) => (fields[code] = value))

            return fields
          },
          getOffsetCell: (offsetY, offsetX) => getOffsetCell(offsetY, offsetX, getState, indexColumn, indexRow, page),
          getLocation: () => ({
            page,
            indexes: [indexRow, indexColumn],
          }),
          getInitCell: () => {
            let initCell = getState?.().data?.[page]?.cells[indexRow]?.[indexColumn]
            if (!initCell) {
              initCell = {} as ExcelCellData
            }
            initCell.key =
              (initCell?.key ?? paginateAxis === 'y') ? getState?.().data?.[1]?.cells[0]?.[indexColumn]?.key : undefined
            initCell.getColumn = () => last(getState?.().data?.[page]?.columns[indexColumn])
            initCell.isLastRow =
              (paginateAxis === 'y' ? Math.ceil(countY / perPage) === page : true) &&
              getState?.().data?.[page]?.cells.length === indexRow + 1
            return initCell
          },
        }
      }),
    )
    return newArray
  }, [])

  return { cellsWithSubscriber }
}
