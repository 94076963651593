import { t } from '@jume/localization'
import { useUnsavedChangesState } from 'packages/core/unsavedChanges/useUnsavedChangesState'

export const useIsDirty = () => {
  const unsavedChanges = useUnsavedChangesState((state) => state.unsavedChanges)
  const clearUnsavedChanges = useUnsavedChangesState((state) => state.clear)
  const unsavedItem = unsavedChanges.find((item) => item.isDirty)
  const isDirty = !!unsavedItem
  const message = unsavedItem?.message || t('unsavedWarning')

  return {
    isDirty,
    message,
    onOk: unsavedItem?.onOk,
    onCancel: unsavedItem?.onCancel,
    clearUnsavedChanges,
  }
}
