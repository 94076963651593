import { differenceBy, sortBy } from 'lodash-es'
import { devtools } from 'packages/core'
import { setOrders } from 'utils/setOrders'
import { create } from 'zustand'

import { UseSteps } from './types'

export const useStepsState = create<UseSteps>()(
  devtools(
    (set) => ({
      steps: [],
      stepToEdit: null,
      setSteps: (steps) => {
        set((prev) => ({
          steps: sortBy([...prev.steps, ...differenceBy(steps, prev.steps, 'id')], 'order'),
        }))
      },
      addStep: (step) => {
        set((prev) => ({
          steps: [...prev.steps, { ...step, order: prev.steps.length + 1 }],
        }))
      },
      removeStep: (step) => {
        set((prev) => ({
          stepToEdit:
            prev.stepToEdit?.order === step.order || !prev.stepToEdit
              ? null
              : {
                  ...prev.stepToEdit,
                  order: step.order < prev.stepToEdit.order ? prev.stepToEdit.order - 1 : prev.stepToEdit.order,
                },
          steps: prev.steps
            .filter((item) => item.order !== step.order)
            .map((item, index) => ({ ...item, order: index + 1 })),
        }))
      },
      setStepToEdit: (step) => {
        set({ stepToEdit: step })
      },
      editStep: (step) => {
        set((prev) => {
          const index = prev.steps.findIndex((item) => item.order === step.order)
          const copy = [...prev.steps]
          copy[index] = step
          return {
            steps: copy,
          }
        })
      },
      replaceStep: (order) => set({ steps: setOrders(order) }),
      copyStep: (step) =>
        set((prev) => ({
          steps: [...prev.steps, { ...step, id: undefined, order: prev.steps.length + 1 }],
        })),
      clear: () => set({ steps: [], stepToEdit: null }),
      clearStep: () => set({ stepToEdit: null }),
    }),
    {
      store: 'jobSteps',
    },
  ),
)
