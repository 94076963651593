import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useGetState, usePrevious, useUpdate } from 'ahooks'
import AvgIcon from 'assets/images/avg.svg?react'
import InfoIcon from 'assets/images/info-mini.svg?react'
import cx from 'clsx'
import {
  CellFields,
  CellLocation,
  ChangedCell,
  ExcelAxisItem,
  ExcelCellData,
  ExcelStateSelectedCells,
  SelectRect,
  SubscribeListener,
  SubscribeListenerWithLastUpdateDate,
} from 'interfaces/excelTable.interfaces'
import { isEqual } from 'lodash-es'
import { Loader, LoaderColors, LoaderTypes } from 'packages/ui/Loader'
import { Skeleton } from 'packages/ui/Skeleton'
import { TextField } from 'packages/ui/TextField'
import { Tooltip } from 'packages/ui/Tooltip'
import { Truncate } from 'packages/ui/Truncate'
import { excelStringToArray } from 'utils/excelStringToArray'

import { ExcelTableContext } from './context/ExcelTableContext'
import classes from './ExcelTable.module.scss'
import { useCellOverflow } from './handlers/useCellOverflow'
import { getValueFormatted } from './helpers/getValueFormatted'
import { isLoadingCell } from './helpers/isLoadingCell'
import { parseSelectRect } from './helpers/parseSelectRect'
import { reproduceCells } from './helpers/reproduceCells'

interface ExcelCellWithSubscriberProps {
  attribute?: string
  subscribeCell?: SubscribeListenerWithLastUpdateDate
  subscribeWidth: SubscribeListener
  getFields?: () => CellFields
  getLocation?: () => CellLocation
  getOffsetCell?: (
    offsetY: number,
    offsetX: number,
  ) => { cell: ExcelCellData | undefined | null; location: CellLocation; fields: CellFields } | undefined
  initCell: ExcelCellData | ExcelAxisItem
  initWidth: number
  initChangedCell?: ChangedCell
  indexRow: number
  indexColumn: number
  isFixed: boolean
  isOverflowed: boolean
  initSelectRect: SelectRect
  refCellsOptions?: MutableRefObject<{
    onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
  }>
}

export const ExcelCellWithSubscriber = ({
  attribute,
  subscribeCell,
  subscribeWidth,
  getFields,
  initCell,
  initWidth,
  getLocation,
  getOffsetCell,
  initChangedCell,
  indexRow,
  indexColumn,
  isFixed,
  isOverflowed,
  initSelectRect,
  refCellsOptions,
}: ExcelCellWithSubscriberProps) => {
  const refCell = useRef<HTMLDivElement | null>(null)
  const { tableState } = useContext(ExcelTableContext)
  const [cell, setCell, getCell] = useGetState<ExcelCellData | ExcelAxisItem>(initCell)
  const [widthCell, setWidthCell] = useState<number>(initWidth)
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isEdit, setIsEdit, getIsEdit] = useGetState(false)
  const [initialValue, setInitialValue] = useState(initChangedCell ? initChangedCell.value : (initCell?.value ?? null))
  const [value, setValue, getValue] = useGetState(initChangedCell ? initChangedCell.value : (initCell?.value ?? null))
  const previousInitValue = usePrevious(initCell?.value)
  const { isOverflowed: isOverflowedInner } = useCellOverflow(refCell, isOverflowed)

  const fontColor = (cell as ExcelCellData)?.fontColor
  const color = (cell as ExcelCellData)?.color
  const isBold = !!(cell as ExcelCellData)?.isBold
  const highlightNegative = (cell as ExcelCellData)?.highlightNegative
  const withBorder = (cell as ExcelCellData)?.getColumn?.()?.withBorder
  const isLastRow = (cell as ExcelCellData)?.isLastRow

  const getValueFormattedInner = (oversize: boolean) => {
    const valueFormatted = getValueFormatted(
      value,
      isFixed ? (initCell as ExcelAxisItem)?.name : attribute,
      tableState,
      oversize,
    )
    if (valueFormatted === null && previousInitValue === undefined) {
      if (initChangedCell) {
        return initChangedCell.value
      }
      return initCell?.value ?? null
    }
    return valueFormatted
  }

  const valueFormatted = getValueFormattedInner(false)
  const valueFormattedWithExponent = getValueFormattedInner(isOverflowedInner)

  const [status, setStatus] = useState<ChangedCell['status'] | null>(initChangedCell?.status ?? null)
  const [error, setError] = useState<string | null>(initChangedCell?.error ?? null)
  const isLoading = isLoadingCell(status)

  const refSum = useRef<number | null>(null)
  const refAvg = useRef<number | null>(null)

  const refFields = useRef<Record<string, string | number | null> | null>(null)
  const refInput = useRef<HTMLTextAreaElement>(null)
  const refEditInput = useRef<HTMLTextAreaElement>(null)
  const refLastUpdateDate = useRef<Date>(new Date())

  const update = useUpdate()
  const refSelectRect = useRef(initSelectRect)

  const getShowSum = useCallback(() => {
    const isOneSelected =
      refSelectRect.current.positions.startRow === refSelectRect.current.positions.endRow &&
      refSelectRect.current.positions.startColumn === refSelectRect.current.positions.endColumn
    return !isOneSelected && refSelectRect.current.right && refSelectRect.current.bottom
  }, [])

  const onMouseOver = () => {
    setIsHovered(true)
  }

  const onMouseOut = () => {
    setIsHovered(false)
  }

  const onDoubleClick = (currentValue: typeof value) => {
    if ((getCell() as Partial<ExcelCellData>).isImmutable === false && getFields) {
      setIsEdit(true)
      setInitialValue(currentValue)
      refFields.current = getFields()
    }
  }

  const onBlur = () => {
    const valueInput = refEditInput.current?.value ?? value
    const newValue = valueInput === '' ? null : Number(valueInput)
    setIsFocused(false)
    setIsEdit(false)
    setInitialValue(valueInput)
    if (getLocation && initialValue !== newValue) {
      tableState.stateChangedCells?.getState().addChangedCells([
        {
          fields: refFields.current || {},
          value: newValue,
          location: getLocation(),
          status: 'need_to_send',
        },
      ])
    }
  }

  const pasteCells = (text: string, columnsCount?: number, rowsCount?: number): (number | null)[][] => {
    if (!getLocation || !getOffsetCell) {
      return []
    }
    const cells = reproduceCells(excelStringToArray(text, true), columnsCount, rowsCount)
    tableState.stateChangedCells?.getState().addChangedCells(
      cells
        .map(
          (line, indexLine) =>
            line
              .map((cellValue, indexCell) => {
                const nextCell = getOffsetCell(indexLine, indexCell)
                if (nextCell?.cell === undefined || nextCell.cell?.isImmutable) {
                  return
                }
                return {
                  fields: nextCell.fields || {},
                  value: cellValue,
                  location: nextCell.location,
                  status: (nextCell.cell === null ? 'waiting_init' : 'need_to_send') as ChangedCell['status'],
                }
              })
              .filter((item) => item && !Number.isNaN(item.value)) as ChangedCell[],
        )
        .flat(),
    )
    return cells
  }

  const onKeyDown = useCallback(async (event: KeyboardEvent): Promise<(number | null)[][] | undefined> => {
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
      setTimeout(() => {
        refEditInput.current?.blur()
      })
    }
    return new Promise((resolve) => {
      let cells: (number | null)[][]

      const columnsCount = refSelectRect.current.positions.endColumn - refSelectRect.current.positions.startColumn + 1
      const rowsCount = refSelectRect.current.positions.endRow - refSelectRect.current.positions.startRow + 1
      if (
        !getIsEdit() &&
        (!Number.isNaN(Number(event.key)) || event.key === '-') &&
        (getCell() as Partial<ExcelCellData>).isImmutable === false
      ) {
        setValue(event.key)
        const currentValue = getValue()
        requestAnimationFrame(() => onDoubleClick(currentValue))
        resolve(undefined)
      } else if (!getIsEdit() && (event.code === 'Backspace' || event.code === 'Delete')) {
        let text = ''
        Array.from({ length: rowsCount }).forEach((_, index) => {
          Array.from({ length: columnsCount - 1 }).forEach(() => {
            text += '\t'
          })
          if (index < rowsCount - 1) {
            text += '\n'
          }
        })
        pasteCells(text, columnsCount, rowsCount)
        resolve(undefined)
      } else if (!getIsEdit() && event.code === 'KeyV' && event.ctrlKey) {
        refInput.current?.focus()
        requestAnimationFrame(() => {
          if (!refInput.current) {
            return
          }
          cells = pasteCells(refInput.current.value, columnsCount, rowsCount)
          refInput.current.value = ''
          refInput.current?.blur()
          resolve(cells)
        })
      }
    })
  }, [])

  useEffect(() => {
    if (!tableState.subscribeChangedCell || !getLocation) {
      return
    }
    return tableState.subscribeChangedCell(
      ({ changedCells }) => changedCells.find((item) => isEqual(item.location, getLocation())),
      (changedCell: ChangedCell | undefined) => {
        if (!changedCell) {
          setStatus(null)
          setError(null)
          return
        }
        requestAnimationFrame(() => {
          setStatus(changedCell?.status ?? null)
          setError(changedCell?.error ?? null)
          setValue(changedCell?.value ?? null)
          setInitialValue(changedCell?.value ?? null)
        })
      },
    )
  }, [tableState.subscribeChangedCell, getLocation])

  useEffect(
    () =>
      subscribeCell?.(
        (newCell: ExcelCellData | ExcelAxisItem) =>
          setCell((prev) => (isEqual(prev, newCell) && getValue() === newCell.value ? prev : newCell)),
        () => refLastUpdateDate.current,
        () => (getValue() !== '' && getValue() !== null ? Number(getValue()) : null),
      ),
    [subscribeCell],
  )
  useEffect(() => subscribeWidth?.(setWidthCell), [subscribeWidth])

  useEffect(() => {
    if (status === 'need_to_send' || status === 'sent') {
      return
    }
    setValue(cell?.value ?? null)
    setInitialValue(cell?.value ?? null)
    if (cell) {
      ;(cell as ExcelCellData).onChangeValue = (newValue) => {
        setValue(newValue)
        setInitialValue(newValue)
      }
    }
  }, [cell])

  useEffect(
    () =>
      tableState.subscribeSelectedCells?.(
        (state) => parseSelectRect(state.selectedCells, isFixed, indexRow, indexColumn),
        (newSelectRect: SelectRect) => {
          const isChanged = !isEqual(
            { ...refSelectRect.current, positions: undefined },
            { ...newSelectRect, positions: undefined },
          )
          refSelectRect.current = newSelectRect
          if (isChanged) {
            update()
          }
        },
      ),
    [tableState.subscribeSelectedCells, isFixed],
  )

  useEffect(
    () =>
      tableState.subscribeSelectedCells?.(
        (state) => state,
        (state: ExcelStateSelectedCells) => {
          refSum.current = state.sumSelectedCells
          refAvg.current = state.avgSelectedCells
          if (getShowSum()) {
            update()
          }
        },
      ),
    [tableState.subscribeSelectedCells],
  )

  useEffect(() => {
    if (refCellsOptions) {
      if (!refCellsOptions.current.onKeyDown) {
        refCellsOptions.current.onKeyDown = []
      }
      if (!refCellsOptions.current.onKeyDown[indexRow]) {
        refCellsOptions.current.onKeyDown[indexRow] = []
      }
      refCellsOptions.current.onKeyDown[indexRow][indexColumn] = onKeyDown
    }
  }, [refCellsOptions, onKeyDown])

  if (cell?.value === undefined) {
    return (
      <div className={classes.cell} style={{ width: widthCell }}>
        <Skeleton className={classes.skeleton} disableAnimation />
      </div>
    )
  }

  return (
    <div
      className={cx(
        classes.cell,
        status && !isEdit && classes[status],
        classes[color],
        fontColor && classes[`font_${fontColor}`],
        {
          [classes.isBold]: isBold,
          [classes.highlightNegative]: highlightNegative && typeof valueFormatted === 'number' && valueFormatted < 0,
          [classes.withBorder]: withBorder,
          [classes.isLastRow]: isLastRow,
          [classes.selectTop]: refSelectRect.current.top,
          [classes.selectBottom]: refSelectRect.current.bottom,
          [classes.selectLeft]: refSelectRect.current.left,
          [classes.selectRight]: refSelectRect.current.right,
        },
      )}
      onBlur={() => setIsFocused(false)}
      onDoubleClick={() => onDoubleClick(getValue())}
      onFocus={() => setIsFocused(true)}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      ref={refCell}
      style={{ width: widthCell }}
    >
      {isEdit && (
        <TextField
          autoFocus
          blurOnArrowClick
          blurOnEnter
          className={cx(classes.input, classes[color], fontColor && classes[`font_${fontColor}`], {
            [classes.isBold]: isBold,
          })}
          classNameContainer={classes.inputCont}
          disableAutoScrollInChrome
          htmlType="number"
          initialValue={value}
          isCell
          onBlur={onBlur}
          onChange={setValue}
          onCtrlEnter={onBlur}
          onFocus={() => setIsFocused(true)}
          ref={refEditInput}
        />
      )}
      {!isEdit && (
        <>
          {isHovered ? (
            <Truncate>{valueFormattedWithExponent}</Truncate>
          ) : (
            <span className="truncateInline">{valueFormattedWithExponent}</span>
          )}
          <span className={cx('truncateInline', classes.valueHidden)} data-value-hidden="true">
            {valueFormatted}
          </span>
        </>
      )}
      {!isEdit && error && status === 'error' && (
        <Tooltip className={classes.errorIcon} isHovered tooltip={error}>
          <InfoIcon className="svg-img" />
        </Tooltip>
      )}
      {!isEdit && (isFocused || refSelectRect.current.isFirst) && (
        <textarea className={classes.inputPaste} ref={refInput} />
      )}

      {isLoading && (
        <div className={classes.loader}>
          <Loader color={LoaderColors.Dark} type={LoaderTypes.SpinnerMini} />
        </div>
      )}

      {getShowSum() && (
        <div className={classes.indicators}>
          {refAvg.current !== null && (
            <div className={classes.avg}>
              <AvgIcon />
              {refAvg.current}
            </div>
          )}
          {refSum.current !== null && <div className={classes.sum}>{refSum.current}</div>}
        </div>
      )}
    </div>
  )
}
