import { FC } from 'react'

import { t } from '@jume/localization'
import FeedbackIcon from 'assets/images/feedback.svg?react'

import classes from './FeedbackButton.module.scss'

interface Props {
  onClick: () => void
}

export const FeedbackButton: FC<Props> = ({ onClick }) => (
  <div className={classes.button} onClick={onClick}>
    <FeedbackIcon className={classes.icon} /> {t('feedback.title')}
  </div>
)
