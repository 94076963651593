import {
  FC,
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  WheelEventHandler,
} from 'react'

import { removeHTMLSelection } from '@jume/utils'
import { useDebounceFn, useMutationObserver } from 'ahooks'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { useSyncStyleProp } from 'hooks/useSyncStyleProp'
import { VirtualScrollPosition } from 'interfaces/excelTable.interfaces'
import { round } from 'lodash-es'

import { ExcelTableContext } from './context/ExcelTableContext'
import classes from './ExcelTable.module.scss'

interface VirtualScrollProps {
  countX: number
  countY: number
  uploadingWidthColumns: number
  uploadingCountColumns: number
  setVirtualScrollPosition: (position: VirtualScrollPosition) => void
  onChangeReady?: (ready: boolean) => void
}

export const VirtualScroll: FC<VirtualScrollProps> = ({
  countX,
  countY,
  uploadingWidthColumns,
  uploadingCountColumns,
  setVirtualScrollPosition,
  onChangeReady,
}) => {
  const { scrollingData, tableState } = useContext(ExcelTableContext)
  const refUploadingWidths = useRef({ uploadingWidthColumns, uploadingCountColumns })
  const refScrollVertical = useRef<HTMLDivElement>(null)
  const refScrollHorizontal = useRef<HTMLDivElement>(null)
  const refScrollElementVertical = useRef<HTMLDivElement>(null)
  const refScrollElementHorizontal = useRef<HTMLDivElement>(null)
  const refScrollCorner = useRef<HTMLDivElement>(null)
  const refScrollingVertical = useRef<{ isScrolling: boolean; startPosition: number; startElementPos: number }>({
    isScrolling: false,
    startPosition: 0,
    startElementPos: 0,
  })
  const refScrollingHorizontal = useRef<{ isScrolling: boolean; startPosition: number; startElementPos: number }>({
    isScrolling: false,
    startPosition: 0,
    startElementPos: 0,
  })
  const refTouchScrollingVertical = useRef<{ isScrolling: boolean; startPosition: number }>({
    isScrolling: false,
    startPosition: 0,
  })
  const refTouchScrollingHorizontal = useRef<{ isScrolling: boolean; startPosition: number }>({
    isScrolling: false,
    startPosition: 0,
  })

  const paddingScrollBottom = 30
  const paddingScrollRight = 15
  const widthScroll = 14

  const onScrollStart = useCallback(() => {
    removeHTMLSelection()
    onChangeReady?.(false)
  }, [])

  const { run: onScrollEndDebounced } = useDebounceFn(
    () => {
      onChangeReady?.(true)
    },
    { wait: 400 },
  )

  const applyTransform = (newLeft: number, newTop: number, notUpdateWrap?: boolean) => {
    if (newLeft < 0 || newTop < 0) {
      return
    }
    if (scrollingData?.refRows.current) {
      scrollingData.refRows.current.style.transform = `translate(-${newLeft}px, -${newTop}px)`
    }
    if (scrollingData?.refWrap.current && !notUpdateWrap) {
      scrollingData.refWrap.current.setAttribute('data-top', String(newTop))
      scrollingData.refWrap.current.setAttribute('data-left', String(newLeft))
    }
    if (scrollingData?.refCommonColumn.current) {
      scrollingData.refCommonColumn.current.style.transform = `translateX(${newLeft}px)`
    }
    if (scrollingData?.refColumns.current) {
      scrollingData.refColumns.current.style.transform = `translateY(${newTop}px)`
    }
    if (scrollingData?.refFixedRows.current) {
      scrollingData.refFixedRows.current.style.transform = `translateY(-${newTop}px)`
    }
    onScrollStart()
    onScrollEndDebounced()
  }

  const scrollTo = useCallback((percentY?: number, percentX?: number) => {
    if (
      !scrollingData?.refWrap.current ||
      !scrollingData?.refCont.current ||
      !scrollingData?.refRows.current ||
      !refScrollElementVertical.current ||
      !refScrollElementHorizontal.current
    ) {
      return
    }

    let newTop = Number(scrollingData?.refWrap.current.getAttribute('data-top') || 0)
    if (percentY !== undefined) {
      const currentCountY = Number(scrollingData?.refCont.current.getAttribute('data-count-y') || 0)
      const heightTable = currentCountY * tableState.heightRow + paddingScrollBottom
      const heightViewport =
        scrollingData?.refCont.current.offsetHeight - (scrollingData?.refColumns.current?.offsetHeight || 0)
      newTop = percentY * (heightTable - heightViewport)
    }

    let newLeft = Number(scrollingData?.refWrap.current.getAttribute('data-left') || 0)
    if (percentX !== undefined) {
      const currentCountX = Number(scrollingData?.refCont.current.getAttribute('data-count-x') || 0)
      const widthTable =
        (currentCountX - refUploadingWidths.current.uploadingCountColumns) * tableState.defaultWidthColumn +
        refUploadingWidths.current.uploadingWidthColumns +
        paddingScrollRight
      const widthViewport = scrollingData?.refCont.current.offsetWidth
      newLeft = percentX * (widthTable - widthViewport)
    }

    applyTransform(newLeft, newTop)
  }, [])

  const scrollElementVerticalMoveTo = useCallback((percent: number) => {
    if (refScrollElementVertical.current && refScrollVertical.current) {
      let newPercent = percent
      if (percent > 1) {
        scrollTo(1, undefined)
        newPercent = 1
      }
      if (percent < 0) {
        newPercent = 0
        scrollTo(0, undefined)
      }
      const heightScrolling = refScrollVertical.current.offsetHeight - refScrollElementVertical.current.offsetHeight
      let translateY = round(heightScrolling * newPercent)
      if (translateY < 0) {
        translateY = 0
      }
      if (translateY > heightScrolling) {
        translateY = heightScrolling
      }
      refScrollElementVertical.current.style.transform = `translateY(${translateY}px)`
      refScrollElementVertical.current.setAttribute('data-pos', String(translateY))
    }
  }, [])

  const scrollElementHorizontalMoveTo = useCallback((percent: number) => {
    if (refScrollElementHorizontal.current && refScrollHorizontal.current) {
      let newPercent = percent
      if (percent > 1) {
        scrollTo(undefined, 1)
        newPercent = 1
      }
      if (percent < 0) {
        newPercent = 0
        scrollTo(undefined, 0)
      }
      const widthScrolling = refScrollHorizontal.current.offsetWidth - refScrollElementHorizontal.current.offsetWidth
      let translateX = round(widthScrolling * newPercent)
      if (translateX < 0) {
        translateX = 0
      }
      if (translateX > widthScrolling) {
        translateX = widthScrolling
      }
      refScrollElementHorizontal.current.style.transform = `translateX(${translateX}px)`
      refScrollElementHorizontal.current.setAttribute('data-pos', String(translateX))
    }
  }, [])

  const getScrollY = useCallback(
    (newCountY = countY) => {
      const heightTable = newCountY * tableState.heightRow + paddingScrollBottom
      const heightViewport =
        (scrollingData?.refCont.current?.offsetHeight || 0) - (scrollingData?.refColumns.current?.offsetHeight || 0)
      if (heightViewport && heightViewport > 0) {
        const heightScrolling = heightTable - heightViewport
        const heightScrollElement = (heightViewport / heightTable) * 100
        const isHiddenScroll = heightScrollElement >= 100 || heightScrollElement < 0
        return { heightScrolling, heightScrollElement, isHiddenScroll }
      }
    },
    [countY],
  )

  const getScrollX = useCallback(
    (
      newCountX = countX,
      newUploadingCountColumns = uploadingCountColumns,
      newUploadingWidthColumns = uploadingWidthColumns,
    ) => {
      const widthTable =
        (newCountX - newUploadingCountColumns) * tableState.defaultWidthColumn +
        newUploadingWidthColumns +
        paddingScrollRight
      const widthViewport = scrollingData?.refCont.current?.offsetWidth
      if (widthViewport && widthViewport > 0) {
        const widthScrolling = widthTable - widthViewport
        const widthScrollElement = (widthViewport / widthTable) * 100
        const isHiddenScroll = widthScrollElement >= 100 || widthScrollElement < 0
        return { widthScrolling, widthScrollElement, isHiddenScroll }
      }
    },
    [countX, uploadingCountColumns, uploadingWidthColumns],
  )

  const resolveScrolls = useCallback(
    (scrollY?: ReturnType<typeof getScrollY>, scrollX?: ReturnType<typeof getScrollX>) => {
      if (!scrollY) {
        scrollY = getScrollY()
      }
      if (!scrollX) {
        scrollX = getScrollX()
      }
      if (
        !scrollY ||
        !scrollX ||
        !refScrollHorizontal.current ||
        !refScrollVertical.current ||
        !refScrollCorner.current
      ) {
        return
      }
      if (scrollY.isHiddenScroll || scrollX.isHiddenScroll) {
        refScrollHorizontal.current.style.right = ''
        refScrollVertical.current.style.bottom = ''
        refScrollCorner.current.style.opacity = '0'
        refScrollCorner.current.style.pointerEvents = 'none'
      }
      if (!scrollY.isHiddenScroll && !scrollX.isHiddenScroll) {
        refScrollHorizontal.current.style.right = `${widthScroll}px`
        refScrollVertical.current.style.bottom = `${widthScroll}px`
        refScrollCorner.current.style.opacity = ''
        refScrollCorner.current.style.pointerEvents = ''
      }
    },
    [countY, countX],
  )

  const onChangeCountY = useCallback((scrollY?: ReturnType<typeof getScrollY>) => {
    if (scrollY && refScrollVertical.current && refScrollElementVertical.current && refScrollHorizontal.current) {
      const { heightScrolling, heightScrollElement, isHiddenScroll } = scrollY
      if (isHiddenScroll) {
        refScrollVertical.current.style.opacity = '0'
        refScrollVertical.current.style.pointerEvents = 'none'
      } else {
        refScrollElementVertical.current.style.height = `${heightScrollElement}%`
        refScrollVertical.current.style.opacity = ''
        refScrollVertical.current.style.pointerEvents = ''
      }
      const newTop = Number(scrollingData?.refWrap.current?.getAttribute('data-top') || 0)
      scrollElementVerticalMoveTo(newTop / heightScrolling)
    }
  }, [])

  const onChangeCountX = useCallback((scrollX?: ReturnType<typeof getScrollX>) => {
    if (scrollX && refScrollHorizontal.current && refScrollElementHorizontal.current && refScrollVertical.current) {
      const { widthScrolling, widthScrollElement, isHiddenScroll } = scrollX
      if (isHiddenScroll) {
        refScrollHorizontal.current.style.opacity = '0'
        refScrollHorizontal.current.style.pointerEvents = 'none'
      } else {
        refScrollElementHorizontal.current.style.width = `${widthScrollElement}%`
        refScrollHorizontal.current.style.opacity = ''
        refScrollHorizontal.current.style.pointerEvents = ''
      }
      const newLeft = Number(scrollingData?.refWrap.current?.getAttribute('data-left') || 0)
      scrollElementHorizontalMoveTo(newLeft / widthScrolling)
    }
  }, [])

  const onScroll = useCallback((deltaY: number, deltaX: number) => {
    if (!scrollingData?.refCont.current || !scrollingData?.refRows.current) {
      return
    }
    const oldTop = Number(scrollingData?.refWrap.current?.getAttribute('data-top') || 0)
    let newTop = oldTop + deltaY

    const oldLeft = Number(scrollingData?.refWrap.current?.getAttribute('data-left') || 0)
    let newLeft = oldLeft + deltaX

    const currentCountY = Number(scrollingData?.refCont.current.getAttribute('data-count-y') || 0)
    const heightTable = currentCountY * tableState.heightRow + paddingScrollBottom
    const heightViewport =
      scrollingData?.refCont.current.offsetHeight - (scrollingData?.refColumns.current?.offsetHeight || 0)
    const heightScrolling = heightTable - heightViewport

    const currentCountX = Number(scrollingData?.refCont.current.getAttribute('data-count-x') || 0)
    const widthTable =
      (currentCountX - refUploadingWidths.current.uploadingCountColumns) * tableState.defaultWidthColumn +
      refUploadingWidths.current.uploadingWidthColumns +
      paddingScrollRight
    const widthViewport = scrollingData?.refCont.current.offsetWidth
    const widthScrolling = widthTable - widthViewport

    if (newTop > heightScrolling) {
      newTop = heightScrolling
    }
    if (newTop < 0) {
      newTop = 0
    }
    if (newLeft > widthScrolling) {
      newLeft = widthScrolling
    }
    if (newLeft < 0) {
      newLeft = 0
    }

    onScrollStart()
    applyTransform(newLeft, newTop)

    scrollElementVerticalMoveTo(newTop / heightScrolling)
    scrollElementHorizontalMoveTo(newLeft / widthScrolling)
  }, [])

  const onWheel: WheelEventHandler<HTMLDivElement> = useCallback((event) => {
    if (event.shiftKey) {
      onScroll(event.deltaX, event.deltaY)
    } else {
      onScroll(event.deltaY, event.deltaX)
    }
  }, [])

  const getPositionScroll = (y?: number, x?: number) => {
    if (!scrollingData?.refCont.current || !scrollingData?.refWrap.current) {
      return { newTop: 0, newLeft: 0, heightScrolling: 0, widthScrolling: 0 }
    }

    const startX = x ?? (Number(scrollingData?.refWrap.current.getAttribute('data-left')) || 0)
    const startY = y ?? (Number(scrollingData?.refWrap.current.getAttribute('data-top')) || 0)

    const currentCountY = Number(scrollingData?.refCont.current.getAttribute('data-count-y') || 0)
    const heightTable = currentCountY * tableState.heightRow + paddingScrollBottom
    const heightViewport =
      scrollingData?.refCont.current.offsetHeight - (scrollingData?.refColumns.current?.offsetHeight || 0)
    const maxTop = heightTable - heightViewport
    let newTop = startY < 0 ? 0 : startY > maxTop ? maxTop : startY
    if (newTop < 0) {
      newTop = 0
    }

    const currentCountX = Number(scrollingData?.refCont.current.getAttribute('data-count-x') || 0)
    const widthTable =
      (currentCountX - refUploadingWidths.current.uploadingCountColumns) * tableState.defaultWidthColumn +
      refUploadingWidths.current.uploadingWidthColumns +
      paddingScrollRight
    const widthViewport = scrollingData?.refCont.current.offsetWidth
    const maxLeft = widthTable - widthViewport
    let newLeft = startX < 0 ? 0 : startX > maxLeft ? maxLeft : startX
    if (newLeft < 0) {
      newLeft = 0
    }

    const heightScrolling = heightTable - heightViewport
    const widthScrolling = widthTable - widthViewport

    return { newTop, newLeft, heightScrolling, widthScrolling }
  }

  const scrollToPx = useCallback((y: number, x: number) => {
    if (
      !scrollingData?.refCont.current ||
      !scrollingData?.refRows.current ||
      !refScrollElementVertical.current ||
      !refScrollElementHorizontal.current
    ) {
      return
    }
    const oldTop = Number(scrollingData?.refWrap.current?.getAttribute('data-top') || 0)
    const oldLeft = Number(scrollingData?.refWrap.current?.getAttribute('data-left') || 0)
    const { newTop, newLeft, heightScrolling, widthScrolling } = getPositionScroll(y, x)

    if (newTop !== oldTop || newLeft !== oldLeft) {
      applyTransform(newLeft, newTop)
      scrollElementVerticalMoveTo(newTop / heightScrolling)
      scrollElementHorizontalMoveTo(newLeft / widthScrolling)
    }
  }, [])

  const onScrollMoveVertical = useCallback((pageY: number) => {
    if (refScrollingVertical.current.isScrolling && refScrollElementVertical.current && refScrollVertical.current) {
      const oldPosY = refScrollingVertical.current.startElementPos
      const newPosY = oldPosY + (pageY - refScrollingVertical.current.startPosition)
      refScrollElementVertical.current.setAttribute('data-pos', String(newPosY))
      let translateY = newPosY
      if (translateY < 0) {
        translateY = 0
      }
      const heightScrolling = refScrollVertical.current.offsetHeight - refScrollElementVertical.current.offsetHeight
      if (translateY > heightScrolling) {
        translateY = heightScrolling
      }

      refScrollElementVertical.current.style.transform = `translateY(${translateY}px)`
      scrollTo(translateY ? translateY / heightScrolling : 0)
    }
  }, [])

  const onScrollMoveHorizontal = useCallback((pageX: number) => {
    if (
      refScrollingHorizontal.current.isScrolling &&
      refScrollElementHorizontal.current &&
      refScrollHorizontal.current
    ) {
      const oldPosX = refScrollingHorizontal.current.startElementPos
      const newPosX = oldPosX + (pageX - refScrollingHorizontal.current.startPosition)
      refScrollElementHorizontal.current.setAttribute('data-pos', String(newPosX))
      let translateX = newPosX
      if (translateX < 0) {
        translateX = 0
      }
      const widthScrolling = refScrollHorizontal.current.offsetWidth - refScrollElementHorizontal.current.offsetWidth
      if (translateX > widthScrolling) {
        translateX = widthScrolling
      }

      refScrollElementHorizontal.current.style.transform = `translateX(${translateX}px)`
      scrollTo(undefined, translateX ? translateX / widthScrolling : 0)
    }
  }, [])

  const onMouseMove: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    if (event.buttons === 1) {
      onScrollMoveVertical(event.pageY)
      onScrollMoveHorizontal(event.pageX)
    }
  }, [])

  const onTouchMove: TouchEventHandler<HTMLDivElement> = useCallback((event) => {
    if (refScrollingVertical.current.isScrolling) {
      onScrollMoveVertical(event.changedTouches[0]?.pageY)
    }
    if (refScrollingHorizontal.current.isScrolling) {
      onScrollMoveHorizontal(event.changedTouches[0]?.pageX)
    }
    if (refTouchScrollingVertical.current.isScrolling || refTouchScrollingHorizontal.current.isScrolling) {
      const deltaY = refTouchScrollingVertical.current.startPosition - event.changedTouches[0]?.pageY
      const deltaX = refTouchScrollingHorizontal.current.startPosition - event.changedTouches[0]?.pageX
      onScroll(deltaY, deltaX)
      refTouchScrollingVertical.current.startPosition = event.changedTouches[0]?.pageY
      refTouchScrollingHorizontal.current.startPosition = event.changedTouches[0]?.pageX
    }
  }, [])

  const onScrollStartVertical = useCallback((pageY: number) => {
    if (!refScrollElementVertical.current || !refScrollVertical.current) {
      return
    }
    refScrollingVertical.current = {
      isScrolling: true,
      startPosition: pageY,
      startElementPos: Number(refScrollElementVertical.current.getAttribute('data-pos') || 0),
    }
    if (refScrollingVertical.current.startElementPos < 0) {
      refScrollingVertical.current.startElementPos = 0
    }
    const heightScrolling = refScrollVertical.current.offsetHeight - refScrollElementVertical.current.offsetHeight
    if (refScrollingVertical.current.startElementPos > heightScrolling) {
      refScrollingVertical.current.startElementPos = heightScrolling
    }
    refScrollElementVertical.current.setAttribute('data-is-scrolling', 'true')
    scrollingData?.refWrap.current?.setAttribute('data-is-scrolling', 'true')
  }, [])

  const onScrollStartHorizontal = useCallback((pageX: number) => {
    if (!refScrollElementHorizontal.current || !refScrollHorizontal.current) {
      return
    }
    refScrollingHorizontal.current = {
      isScrolling: true,
      startPosition: pageX,
      startElementPos: Number(refScrollElementHorizontal.current.getAttribute('data-pos') || 0),
    }
    if (refScrollingHorizontal.current.startElementPos < 0) {
      refScrollingHorizontal.current.startElementPos = 0
    }
    const widthScrolling = refScrollHorizontal.current.offsetWidth - refScrollElementHorizontal.current.offsetWidth
    if (refScrollingHorizontal.current.startElementPos > widthScrolling) {
      refScrollingHorizontal.current.startElementPos = widthScrolling
    }
    refScrollElementHorizontal.current.setAttribute('data-is-scrolling', 'true')
    scrollingData?.refWrap.current?.setAttribute('data-is-scrolling', 'true')
  }, [])

  const onMouseDownVertical: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    if (refScrollElementVertical.current && event.target === refScrollElementVertical.current) {
      onScrollStartVertical(event.pageY)
    }
    onScrollStart()
  }, [])

  const onMouseDownHorizontal: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    if (refScrollElementHorizontal.current && event.target === refScrollElementHorizontal.current) {
      onScrollStartHorizontal(event.pageX)
    }
    onScrollStart()
  }, [])

  const onTouchStartVertical: TouchEventHandler<HTMLDivElement> = useCallback((event) => {
    if (refScrollElementVertical.current && event.target === refScrollElementVertical.current) {
      onScrollStartVertical(event.changedTouches[0]?.pageY)
    }
    onScrollStart()
  }, [])

  const onTouchStartHorizontal: TouchEventHandler<HTMLDivElement> = useCallback((event) => {
    if (refScrollElementHorizontal.current && event.target === refScrollElementHorizontal.current) {
      onScrollStartHorizontal(event.changedTouches[0]?.pageX)
    }
    onScrollStart()
  }, [])

  const onTouchStartContent: TouchEventHandler<HTMLDivElement> = useCallback((event) => {
    if (
      refScrollElementVertical.current &&
      refScrollVertical.current &&
      event.target !== refScrollVertical.current &&
      !refScrollVertical.current.contains(event.target as HTMLDivElement) &&
      refScrollElementHorizontal.current &&
      refScrollHorizontal.current &&
      event.target !== refScrollHorizontal.current &&
      !refScrollHorizontal.current.contains(event.target as HTMLDivElement)
    ) {
      refTouchScrollingVertical.current = { isScrolling: true, startPosition: event.changedTouches[0]?.pageY }
      refTouchScrollingHorizontal.current = { isScrolling: true, startPosition: event.changedTouches[0]?.pageX }
    }
  }, [])

  const onScrollEnd = useCallback(() => {
    refScrollingVertical.current = {
      isScrolling: false,
      startPosition: 0,
      startElementPos: refScrollingVertical.current.startElementPos,
    }
    refTouchScrollingVertical.current = {
      isScrolling: false,
      startPosition: 0,
    }
    refScrollElementVertical.current?.removeAttribute('data-is-scrolling')

    refScrollingHorizontal.current = {
      isScrolling: false,
      startPosition: 0,
      startElementPos: refScrollingHorizontal.current.startElementPos,
    }
    refTouchScrollingHorizontal.current = {
      isScrolling: false,
      startPosition: 0,
    }
    refScrollElementHorizontal.current?.removeAttribute('data-is-scrolling')

    scrollingData?.refWrap.current?.removeAttribute('data-is-scrolling')
  }, [])

  useMutationObserver(
    (mutations) => {
      mutations.forEach((mutation) => {
        if (!mutation.attributeName) {
          return
        }
        const scrollY = getScrollY(Number((mutation.target as HTMLElement).getAttribute(mutation.attributeName)))
        const scrollX = getScrollX(
          Number((mutation.target as HTMLElement).getAttribute(mutation.attributeName)),
          refUploadingWidths.current.uploadingCountColumns,
          refUploadingWidths.current.uploadingWidthColumns,
        )
        if (mutation.attributeName === 'data-count-y') {
          onChangeCountY(scrollY)
        }
        if (mutation.attributeName === 'data-count-x') {
          onChangeCountX(scrollX)
        }
        resolveScrolls(scrollY, scrollX)
      })
    },
    scrollingData?.refCont,
    {
      attributes: true,
      attributeFilter: ['data-count-x', 'data-count-y'],
    },
  )

  const onChangeSizeCont = (isApplyTransform?: boolean) => {
    const target = scrollingData?.refWrap.current
    if (!target) {
      return
    }
    const { newTop, newLeft } = getPositionScroll()
    const position: VirtualScrollPosition = {
      startX: newLeft,
      startY: newTop,
      endX: newLeft + (scrollingData?.refCont.current?.offsetWidth || 0),
      endY:
        newTop +
        (scrollingData?.refCont.current?.offsetHeight || 0) -
        (scrollingData?.refColumns.current?.offsetHeight || 0),
    }
    setVirtualScrollPosition(position)
    if (isApplyTransform && position.startX && position.startY) {
      applyTransform(position.startX, position.startY, true)
    }
  }

  useMutationObserver(() => onChangeSizeCont(), scrollingData?.refWrap, {
    attributes: true,
    attributeFilter: ['data-top', 'data-left'],
  })

  useSyncStyleProp(scrollingData?.refColumns, refScrollVertical, 'clientHeight', 'top', 3, [
    countY,
    countX,
    uploadingCountColumns,
    uploadingWidthColumns,
  ])
  useResizeObserver({
    ref: scrollingData?.refCont,
    callback: () => {
      const scrollY = getScrollY(countY)
      const scrollX = getScrollX(countX, uploadingCountColumns, uploadingWidthColumns)
      onChangeCountY(scrollY)
      onChangeCountX(scrollX)
      resolveScrolls(scrollY, scrollX)
      onChangeSizeCont(true)
    },
    dependencies: [countY, countX, uploadingCountColumns, uploadingWidthColumns],
  })

  useEffect(() => {
    if (!scrollingData) {
      return
    }
    window.addEventListener('mouseup', onScrollEnd)
    window.addEventListener('touchend', onScrollEnd)
    scrollingData.onMouseMove = onMouseMove
    scrollingData.onTouchMove = onTouchMove
    scrollingData.onTouchStartContent = onTouchStartContent
    scrollingData.onWheel = onWheel
    scrollingData.refFixedRows.current?.addEventListener('wheel', onWheel as any)
    scrollingData.refFixedRows.current?.addEventListener('touchstart', onTouchStartContent as any)
    scrollingData.refFixedRows.current?.addEventListener('touchmove', onTouchMove as any)
    scrollingData.scrollToPx = scrollToPx

    return () => {
      window.removeEventListener('mouseup', onScrollEnd)
      window.removeEventListener('touchend', onScrollEnd)
      scrollingData?.refFixedRows.current?.removeEventListener('wheel', onWheel as any)
      scrollingData?.refFixedRows.current?.removeEventListener('touchstart', onTouchStartContent as any)
      scrollingData?.refFixedRows.current?.removeEventListener('touchmove', onTouchMove as any)
    }
  }, [scrollingData])

  useEffect(() => {
    refUploadingWidths.current = { uploadingCountColumns, uploadingWidthColumns }
  }, [uploadingCountColumns, uploadingWidthColumns])

  return (
    <>
      <div className={classes.scrollVertical} data-scroll-element="true" ref={refScrollVertical}>
        <div
          className={classes.scrollElement}
          onMouseDown={onMouseDownVertical}
          onTouchStart={onTouchStartVertical}
          ref={refScrollElementVertical}
        />
      </div>
      <div className={classes.scrollHorizontal} data-scroll-element="true" ref={refScrollHorizontal}>
        <div
          className={classes.scrollElement}
          onMouseDown={onMouseDownHorizontal}
          onTouchStart={onTouchStartHorizontal}
          ref={refScrollElementHorizontal}
        />
      </div>
      <div className={classes.corner} ref={refScrollCorner} />
    </>
  )
}
