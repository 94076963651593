import { createContext, ReactNode } from 'react'

import { GetAllData } from '@jume/api'

export interface PaginationResult<Item = unknown> {
  count: number
  next: number | null
  results: Item[]
  page?: number | null
  perPage?: number
}

interface JumeContextProps<Item = unknown> {
  paginationModel: (data: GetAllData<Item> | undefined) => PaginationResult<Item> | undefined
}

export const createPaginationModel =
  <Item = unknown,>(cb: (data: GetAllData<Item>) => PaginationResult<Item>) =>
  (data: GetAllData<Item> | undefined) =>
    data === undefined ? undefined : cb(data)

const defaultPaginationModel = createPaginationModel((data) => ({
  count: data.count,
  next: data.next,
  results: data.results,
  page: data.page,
  perPage: data.perPage,
}))

const defaultJumeContextValue: JumeContextProps = {
  paginationModel: defaultPaginationModel,
}

export const JumeContext = createContext<JumeContextProps>(defaultJumeContextValue)

export interface JumeProviderProps<Item = unknown> extends Partial<JumeContextProps<Item>> {
  children?: ReactNode
}

export const JumeProvider = <Item = unknown,>({ children, ...props }: JumeProviderProps<Item>): ReactNode => (
  <JumeContext.Provider value={{ ...defaultJumeContextValue, ...props } as any}>{children}</JumeContext.Provider>
)
