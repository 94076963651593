import { deleteUndefined, removeByIndex, updateBy, updateByIndex } from '@jume/utils'
import { uniq } from 'lodash-es'
import { AttributeFilterPartial } from 'modules/filters/attribute/api'
import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseAttributeFiltersParameters } from './types'

export const generateStoreAttributeFilter = (nameStore: string) =>
  create<UseAttributeFiltersParameters>()(
    devtools(
      (set) => ({
        searchQueryAttributeFilter: null,
        setSearchQueryAttributeFilter: (searchQueryAttributeFilter) => set({ searchQueryAttributeFilter }),
        currentAttributeFilter: null,
        setCurrentAttributeFilter: (currentAttributeFilter) =>
          set({ currentAttributeFilter }, undefined, 'setCurrentAttributeFilter'),
        clear: () =>
          set(
            {
              searchQueryAttributeFilter: null,
              currentAttributeFilter: null,
            },
            undefined,
            'clear',
          ),
        updateValues: (attributeId, values) =>
          set(
            (prev) => ({
              currentAttributeFilter: prev.currentAttributeFilter
                ? {
                    ...prev.currentAttributeFilter,
                    attributes: updateBy(
                      prev.currentAttributeFilter?.attributes,
                      (item) => item.attribute?.id === attributeId,
                      (item) => ({
                        ...item,
                        values,
                      }),
                    ),
                  }
                : null,
            }),
            undefined,
            'updateValues',
          ),
        addValues: (attributeId, values, isReplace) =>
          set(
            (prev) => ({
              currentAttributeFilter: prev.currentAttributeFilter
                ? {
                    ...prev.currentAttributeFilter,
                    attributes: updateBy(
                      prev.currentAttributeFilter?.attributes,
                      (item) => item.attribute?.id === attributeId,
                      (item) => ({
                        ...item,
                        values: isReplace ? uniq(values) : uniq([...item.values, ...values]),
                      }),
                    ),
                  }
                : null,
            }),
            undefined,
            'addValues',
          ),
        updateAttribute: (index, attribute) =>
          set(
            (prev) => ({
              currentAttributeFilter: prev.currentAttributeFilter
                ? {
                    ...prev.currentAttributeFilter,
                    attributes: updateByIndex(prev.currentAttributeFilter?.attributes, index, (item) => ({
                      ...item,
                      attribute,
                      values: [],
                    })),
                  }
                : null,
            }),
            undefined,
            'updateAttribute',
          ),
        updateIsEqual: (index, isEqual) =>
          set(
            (prev) => ({
              currentAttributeFilter: prev.currentAttributeFilter
                ? {
                    ...prev.currentAttributeFilter,
                    attributes: updateByIndex(prev.currentAttributeFilter?.attributes, index, (item) => ({
                      ...item,
                      isEqual,
                    })),
                  }
                : null,
            }),
            undefined,
            'updateIsEqual',
          ),
        createAttribute: () =>
          set(
            (prev) => ({
              currentAttributeFilter: {
                ...(prev.currentAttributeFilter || {}),
                attributes: [
                  ...(prev.currentAttributeFilter?.attributes || []),
                  {
                    attribute: null,
                    values: [],
                    isEqual: true,
                  },
                ],
              } as AttributeFilterPartial,
            }),
            undefined,
            'createAttribute',
          ),
        removeAttribute: (index) =>
          set(
            (prev) => {
              const attributes = removeByIndex(prev.currentAttributeFilter?.attributes, index)
              return {
                currentAttributeFilter: prev.currentAttributeFilter
                  ? deleteUndefined({
                      ...prev.currentAttributeFilter,
                      attributes: attributes.length ? attributes : undefined,
                    })
                  : null,
              }
            },
            undefined,
            'removeAttribute',
          ),
        errorCreateAttributeFilter: null,
        setErrorCreateAttributeFilter: (errorCreateAttributeFilter) =>
          set({ errorCreateAttributeFilter }, undefined, 'setErrorCreateAttributeFilter'),
        errorRemoveAttributeFilter: null,
        setErrorRemoveAttributeFilter: (errorRemoveAttributeFilter) =>
          set({ errorRemoveAttributeFilter }, undefined, 'setErrorRemoveAttributeFilter'),
      }),
      {
        store: nameStore,
      },
    ),
  )
