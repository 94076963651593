import { createElement } from 'react'

import cx from 'clsx'
import { findLastIndex } from 'lodash-es'
import classes from 'packages/ui/Draggable/Draggable.module.scss'
import { calculateMovingData } from 'packages/ui/Draggable/helpers/calculateMovingData'
import { getItems } from 'packages/ui/Draggable/helpers/getItems'
import { recalculatePositions } from 'packages/ui/Draggable/helpers/recalculatePositions'
import { PlaceholderStrategy } from 'packages/ui/Draggable/types'

import { equalLevelsStrategy } from './equalLevelsStrategy'

export const placeholderTreeBottomLevelNotOrderStrategy: PlaceholderStrategy = (params) => {
  const {
    childrenList,
    placeholderElement,
    draggableIndex,
    classNamePlaceholder,
    refInitialIndex,
    refRectItem,
    refPlaceholder,
    refDraggableItem,
    refElementPosition,
    refPositions,
    refRealDraggableIndex,
    refList,
    refIsOtherList,
    isDragging,
  } = params

  const items = getItems(params)

  if (refList.current) {
    if (isDragging) {
      refList.current.classList.add(classes.isDragging)
    } else {
      refList.current.classList.remove(classes.isDragging)
      items.forEach((item) => item.classList.remove(classes.onDragExpand))
    }
  }
  if (refInitialIndex.current !== null) {
    if (refRectItem.current && !refIsOtherList.current) {
      childrenList.splice(
        refInitialIndex.current,
        0,
        createElement(placeholderElement, {
          className: cx(classes.placeholder, classNamePlaceholder),
          'data-list-element': true,
          'data-placeholder-element': true,
          'data-initial-draggable-index': refInitialIndex.current,
          'data-draggable-index': draggableIndex,
          key: 'placeholder',
          style: { width: refRectItem.current.width, height: refRectItem.current.height },
          ref: refPlaceholder,
        }),
      )
    }

    const { getLevelPositions } = params.levelsStrategy ?? equalLevelsStrategy(params)
    const levelPositions = getLevelPositions?.()

    if (refList.current && draggableIndex !== null && levelPositions) {
      childrenList.push(
        createElement(placeholderElement, {
          className: classes.levelWrapper,
          'data-level-wrapper-element': true,
          'data-initial-draggable-index': refInitialIndex.current,
          'data-draggable-index': draggableIndex,
          key: 'levelWrapper',
          style: levelPositions,
        }),
      )
    }
  }

  const calculateIndex = (event: MouseEvent) => {
    if (!refDraggableItem.current || !refPositions.current || !refList.current) {
      return null
    }

    const windowX = event.clientX
    const windowY = event.clientY

    const wrapRect = params.refWrap?.current?.getBoundingClientRect()
    if (
      wrapRect &&
      !(windowX <= wrapRect.right && windowX >= wrapRect.left && windowY >= wrapRect.top && windowY <= wrapRect.bottom)
    ) {
      refRealDraggableIndex.current = null
      return null
    }

    const { positions, listTop, items: list } = calculateMovingData(params)
    const top = refDraggableItem.current.getBoundingClientRect().top - listTop

    const positionItem = top + (refRectItem.current?.height || 0) / 2
    const isUp = refElementPosition.current ? refElementPosition.current.startPosition > top : false
    let index = isUp
      ? positions.findIndex((item) => item > positionItem)
      : findLastIndex(positions, (item) => item < positionItem)
    if (index === -1) {
      index = isUp ? refPositions.current.length : 0
    }
    if (index + (isUp ? -1 : 0) >= params.refMetaItems.current.length) {
      refRealDraggableIndex.current = null
      return null
    }
    refRealDraggableIndex.current = index + (isUp ? -1 : 0)

    list.forEach((item) => item.classList.remove(classes.onDragExpand))

    const onDragElements = [
      list[refRealDraggableIndex.current - 1],
      list[refRealDraggableIndex.current],
      list[refRealDraggableIndex.current + 1],
    ]
    onDragElements.forEach((onDragElement) => {
      if (onDragElement) {
        const collapsedArrow = onDragElement.querySelector('[data-collapsed-arrow=true]') as any
        const collapsedArrowRect = collapsedArrow?.getBoundingClientRect()
        if (
          collapsedArrowRect &&
          collapsedArrowRect.top <= windowY &&
          collapsedArrowRect.bottom >= windowY &&
          collapsedArrowRect.left <= windowX &&
          collapsedArrowRect.right >= windowX
        ) {
          onDragElement.classList.add(classes.onDragExpand)
          collapsedArrow?.expand?.()
          const elementPosition = refElementPosition.current ? { ...refElementPosition.current } : null
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              if (refIsOtherList.current) {
                refPositions.current = list.map((item) => item.getBoundingClientRect().top - listTop)
              } else {
                recalculatePositions(params, refDraggableItem.current?.dataset.index, elementPosition, event)
              }
            })
          })
        }
      }
    })

    return index + (isUp ? -1 : 1)
  }

  return {
    childrenList,
    calculateIndex,
  }
}
