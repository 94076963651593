import {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ApiError, getError } from '@jume/api'
import { t } from '@jume/localization'
import { deleteUndefinedInArray, isEmpty, toArray } from '@jume/utils'
import { useDeepCompareEffect, useEventListener, useGetState, usePrevious, useThrottle, useUpdateEffect } from 'ahooks'
import ArrowMicroIcon from 'assets/images/arrow-bottom-micro.svg?react'
import ArrowIcon from 'assets/images/arrow-bottom.svg?react'
import CloseIcon from 'assets/images/close-16.svg?react'
import ErrorIcon from 'assets/images/error.svg?react'
import InfoIcon from 'assets/images/info.svg?react'
import MarkIcon from 'assets/images/mark.svg?react'
import cx from 'clsx'
import { SELECT_ALL } from 'constants/components'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useSyncWidth } from 'hooks/useSyncWidth'
import { CustomChange, WarningColors } from 'interfaces/common.interfaces'
import { OptionTreeItem } from 'interfaces/components.interfaces'
import { difference, isEqual, uniq, uniqBy } from 'lodash-es'
import { ConfirmButtons } from 'packages/ui/ConfirmButtons'
import { Loader, LoaderColors, LoaderTypes } from 'packages/ui/Loader'
import { MarkItem, MarkItemTypes } from 'packages/ui/MarkItem'
import { Skeleton, SkeletonSizes } from 'packages/ui/Skeleton'
import { TextField } from 'packages/ui/TextField'
import { Tooltip } from 'packages/ui/Tooltip'
import { Truncate } from 'packages/ui/Truncate'
import RcSelect, { SelectProps as SelectPropsNative } from 'rc-select'
import { BaseSelectRef } from 'rc-select/lib/BaseSelect'
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select'
import { findOptionByLabel, findOptionDeep } from 'utils/filter'
import { itemsTreeParse } from 'utils/itemsTreeParse'
import { renderElement } from 'utils/renderElement'

import { parseLabel } from './parseLabel'
import { parseValue } from './parseValue'
import classes from './Select.module.scss'

export enum SelectSizes {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum SelectTypes {
  Default = 'default',
  Cell = 'cell',
  Header = 'header',
}

export interface SelectPropsInternal<
  ValueType extends string | number | (number | string)[] = string,
  Meta = unknown,
  Multiple extends boolean = boolean,
  ShowApplyButton extends boolean = boolean,
> {
  size?: SelectSizes
  type?: SelectTypes
  multiple?: Multiple
  showApplyButton?: ShowApplyButton
  label?: string
  showSearch?: boolean
  classNameContainer?: string
  classNameSelect?: string
  classNameOpen?: string
  classNameOpenSelect?: string
  classNameLabel?: string
  classNameDisabled?: string
  classNameTooltip?: string
  options?: OptionTreeItem<Meta>[]
  isTree?: boolean
  defaultValue?: ValueType | null
  onChange?: (
    value: [Multiple] extends [true] ? ValueType : [ShowApplyButton] extends [true] ? ValueType | null : ValueType,
    options?: OptionTreeItem<Meta>[],
  ) => void
  defaultIsReset?: boolean
  offsetLeft?: number
  offsetTop?: number
  getPopupContainer?: () => HTMLElement | null
  closeSelectTrigger?: any
  loading?: boolean
  isFetchingNextPage?: boolean
  value?: ValueType | null
  valueString?: string
  valueStringWhenNotLoaded?: string
  valueTreeWhenNotLoaded?: Record<number, string>
  onScrollPaginate?: () => void
  hasNextPage?: boolean
  onSearch?: (inputValue: string) => void
  actualSearchValue?: string | null
  dropdownRender?: (dropdown: ReactElement, apply: () => ValueType | undefined) => ReactElement
  isCleanable?: boolean
  widthMaxContent?: boolean
  error?: ApiError | string | null
  isError?: boolean
  isChanged?: boolean
  noEditable?: boolean
  onOpenTree?: (key: string | number | null) => void
  showMultipleLabel?: boolean
  labelWithItems?: boolean
  isSelectWithChildren?: boolean
  dropdownMinWidth?: number
  showCounter?: boolean
  showSelectAll?: boolean
  fetchAllPages?: () => Promise<any[] | undefined>
  isFetchingAllPages?: boolean
  loadAllPagesOnSelectAll?: boolean
  total?: number
  isOptionEllipsis?: boolean
  isValueCleared?: boolean
  selectedParentIds?: Record<number, number[]>
  selectedElementsNames?: Record<number, string>
  searchInDropdown?: boolean
  tooltip?: ReactNode
  showErrorIcon?: boolean
  showWarningIcon?: boolean
  showSearchIcon?: boolean
  searchPlaceholder?: string
  warningColor?: WarningColors
  isCustomApply?: boolean
  notFoundContent?: ReactNode
  checkParentWithCheckedChildren?: boolean
  dropdownNotStyled?: boolean
  isLoadingApplyButton?: boolean
  defaultScrollIndex?: number
  initialSelectAll?: boolean
}

export type SelectProps<ValueType extends string | number | (number | string)[], Meta = unknown> = Omit<
  SelectPropsNative<ValueType>,
  'onChange' | 'mode' | 'getPopupContainer' | 'options' | 'value'
> &
  SelectPropsInternal<ValueType, Meta>

const InternalSelect = <
  ValueType extends string | number | (number | string)[],
  OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType,
  Meta = unknown,
>(
  {
    className,
    classNameContainer,
    classNameSelect,
    classNameOpen,
    classNameOpenSelect,
    classNameLabel,
    classNameDisabled,
    classNameTooltip,
    dropdownClassName,
    children,
    size = SelectSizes.Medium,
    type = SelectTypes.Default,
    multiple,
    placeholder,
    searchPlaceholder,
    label,
    showSearch,
    onChange,
    options,
    isTree,
    defaultValue,
    defaultIsReset,
    value,
    onDropdownVisibleChange,
    open,
    offsetLeft = -1,
    offsetTop = 10,
    disabled,
    getPopupContainer,
    closeSelectTrigger,
    loading,
    isFetchingNextPage,
    valueString,
    valueStringWhenNotLoaded,
    valueTreeWhenNotLoaded,
    onScrollPaginate,
    hasNextPage,
    onSearch,
    actualSearchValue,
    dropdownRender,
    isCleanable,
    widthMaxContent,
    error,
    isError = !!error,
    noEditable,
    isChanged,
    onOpenTree,
    showApplyButton,
    showMultipleLabel = !isTree && multiple,
    labelWithItems,
    isSelectWithChildren,
    dropdownMinWidth,
    showCounter,
    showSelectAll,
    fetchAllPages,
    isFetchingAllPages,
    loadAllPagesOnSelectAll,
    total,
    isOptionEllipsis,
    isValueCleared,
    selectedParentIds,
    selectedElementsNames,
    searchInDropdown = multiple,
    tooltip,
    showErrorIcon = !!error,
    showWarningIcon,
    showSearchIcon,
    warningColor = WarningColors.Danger,
    isCustomApply,
    notFoundContent,
    checkParentWithCheckedChildren,
    dropdownNotStyled,
    isLoadingApplyButton,
    defaultScrollIndex,
    initialSelectAll,
    ...props
  }: SelectProps<ValueType, Meta>,
  ref?: Ref<BaseSelectRef>,
) => {
  const [isTop, setIsTop] = useState(false)
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number>()
  const [openInternal, setOpenInternal, getOpenInternal] = useGetState(open)
  const [selectedOptions, setSelectedOptions] = useState<OptionTreeItem<Meta>[]>([])
  const [valueInternal, setValueInternal] = useState<ValueType | null>()
  const [valueApplied, setValueApplied] = useState<ValueType | null>()
  const [searchValue, setSearchValue] = useState('')
  const [openTreeItems, setOpenTreeItems] = useState<(string | number | null)[]>([])

  const refSearchInput = useRef<HTMLInputElement>(null)
  const refLabel = useRef<HTMLSpanElement>(null)
  const refScroll = useRef<HTMLDivElement>(null)
  const refSelect = useRef<BaseSelectRef & CustomChange>(null)
  useImperativeHandle(ref, () => refSelect.current as BaseSelectRef)

  const labelInternal = parseLabel<ValueType>({
    isTree,
    multiple,
    showMultipleLabel,
    options,
    value,
    valueInternal,
    valueStringWhenNotLoaded,
    valueTreeWhenNotLoaded,
    label,
    labelWithItems,
    selectedElementsNames,
    selectedOptions,
    placeholder,
    showApplyButton,
    showCounter,
    valueString,
  })

  const valueCombine = parseValue<ValueType, Meta>({
    valueStringWhenNotLoaded,
    value,
    valueInternal,
    setValueInternal,
    options,
    multiple,
    isTree,
    showSelectAll,
    total,
  })

  const isValueStringFromProps = labelInternal && !multiple && valueString
  const refWrap = useRef<HTMLDivElement>(null)
  const refDropdown = useRef<HTMLDivElement>(null)
  const throttledValue = useThrottle(searchValue, { wait: 200 })
  const searchValueCombine = searchValue ? searchValue : (actualSearchValue ?? '')
  const actualSearchValueCombine = actualSearchValue ?? throttledValue
  const isSearch = !!searchValue && !!actualSearchValueCombine && searchValue === actualSearchValueCombine
  const isSearchPrevious = usePrevious(isSearch)
  const isSearchCombine =
    !!actualSearchValueCombine && searchValue !== actualSearchValueCombine ? !!isSearchPrevious : isSearch
  const valueSorted = useMemo(() => (Array.isArray(value) ? [...value].sort() : value), [value])

  const applied = useMemo(
    () =>
      isEqual(
        (Array.isArray(valueInternal) ? valueInternal.filter((item) => item !== SELECT_ALL).sort() : valueInternal) ??
          [],
        (Array.isArray(valueApplied) ? [...valueApplied].sort() : valueApplied) ?? [],
      ),
    [valueInternal, valueApplied],
  )

  const counter = showCounter
    ? (showApplyButton ? toArray(valueApplied) : toArray(valueInternal).filter((item) => item !== SELECT_ALL)).length -
      1
    : 0

  const getCurrentOptions = (values: ValueType, reset?: boolean) => {
    const currentOptions = deleteUndefinedInArray(
      toArray(values).map((item) => findOptionDeep(item, options)),
    ) as OptionTreeItem<Meta>[]
    if (multiple && (isTree || showApplyButton) && !reset) {
      currentOptions.push(...selectedOptions)
    }
    return uniqBy(currentOptions, 'value')
  }

  const refOptions = useRef(options)
  refOptions.current = options

  const getCurrentOptionsByLabel = (str: string) => {
    const currentOptions = deleteUndefinedInArray(
      toArray(str).map((item) => findOptionByLabel(item, options)),
    ) as OptionTreeItem<Meta>[]
    return uniqBy(currentOptions, 'value')
  }

  const getValueByLabel = (optionLabel: string) => {
    const currentOptions = getCurrentOptionsByLabel(optionLabel)
    if (currentOptions.length === 0) {
      return
    }
    return currentOptions[0].value as ValueType
  }

  const onChangeInternal = (newValue: ValueType) => {
    let parsedValue = newValue ?? null

    if (
      Array.isArray(valueInternal) &&
      Array.isArray(newValue) &&
      isEqual(difference(valueInternal, newValue), [SELECT_ALL])
    ) {
      parsedValue = [] as unknown as ValueType
    }

    let currentOptions = getCurrentOptions(parsedValue)

    if (!showSearch) {
      setSearchValue('')
    }

    if (
      Array.isArray(parsedValue) &&
      showSelectAll &&
      (parsedValue.includes(SELECT_ALL) ||
        (!searchValue && options && parsedValue && parsedValue.length === options.length))
    ) {
      const newValues = [SELECT_ALL, ...(options?.map((item) => item.value) ?? [])]
      if (multiple && (isTree || showApplyButton)) {
        newValues.push(...selectedOptions.map((item) => item.value))
      }
      setValueInternal((prev) => {
        const uniqNewValues = uniq(newValues)

        if (
          loadAllPagesOnSelectAll &&
          loadAllPagesOnSelectAll &&
          multiple &&
          fetchAllPages &&
          uniqNewValues.includes(SELECT_ALL) &&
          (prev === undefined || (Array.isArray(prev) && !prev.includes(SELECT_ALL)))
        ) {
          fetchAllPages().then(() => {
            requestAnimationFrame(() => {
              const allValues = uniq([SELECT_ALL, ...(refOptions.current?.map((item) => item.value) ?? [])])
              setValueInternal(allValues as ValueType)
              setSelectedOptions(uniqBy([...(refOptions.current ?? []), ...selectedOptions], 'value'))
            })
          })
        }

        if (!showApplyButton && !isCustomApply) {
          onChange?.(uniqNewValues.filter((item) => item !== SELECT_ALL) as ValueType, currentOptions)
        }

        return uniqNewValues as ValueType
      })
      setSelectedOptions(uniqBy([...(options ?? []), ...selectedOptions], 'value'))
    } else {
      let savingValue = (
        Array.isArray(parsedValue) ? currentOptions.map((item) => item.value) : parsedValue
      ) as ValueType
      currentOptions = currentOptions.filter((item) => {
        const isExtra = item.parentIds?.some((id) => toArray(savingValue).includes(id as any))
        if (isExtra) {
          savingValue = (
            Array.isArray(savingValue) ? savingValue.filter((valueId) => valueId !== item.value) : savingValue
          ) as ValueType
        }
        return !isExtra
      })
      setValueInternal(savingValue)
      setSelectedOptions(currentOptions)
      if (!showApplyButton && !isCustomApply) {
        onChange?.(parsedValue, currentOptions)
      }
    }
  }

  const onInsertChange = (optionLabel: string) => {
    const optionValue = getValueByLabel(optionLabel)
    if (optionValue) {
      onChangeInternal(optionValue)
    }
  }

  const onDeselect = (selectedValue?: string | number) => {
    requestAnimationFrame(() => {
      if (selectedValue === undefined) {
        return
      }
      const currentOption = findOptionDeep(selectedValue, options)
      if (!currentOption && selectedValue !== SELECT_ALL) {
        return
      }

      if (refSearchInput.current && searchValue !== refSearchInput.current.value) {
        return
      }

      setValueInternal((prev) => {
        if (selectedValue === SELECT_ALL) {
          setSelectedOptions([])
          return [] as never as ValueType
        }
        if (Array.isArray(prev)) {
          setSelectedOptions((items) => items.filter((item) => item.value !== selectedValue))
          return prev?.filter((item) => item !== SELECT_ALL && item !== selectedValue) as ValueType
        }
        return prev
      })
    })
  }

  const onSearchInternal = (inputValue: string) => {
    onSearch?.(inputValue)
    setSearchValue(inputValue)
  }

  const getPopupContainerInternal = (): HTMLElement => {
    const container: HTMLElement = document.querySelector('[data-container]') ?? document.body
    if (getPopupContainer) {
      return getPopupContainer() ?? container
    }
    return container
  }

  const onDropdownVisibleChangeInternal = (newOpen: boolean) => {
    if (newOpen) {
      requestAnimationFrame(async () => {
        const rect = refWrap.current?.getBoundingClientRect()
        await renderElement(refDropdown)
        const rectDropdown = refDropdown.current?.getBoundingClientRect()
        if (rect && newOpen) {
          const bottomHeight = Math.min(window.innerHeight - rect.top - rect.height - offsetTop - 15, 490)
          const topHeight = Math.min(rect.top - offsetTop - 15, 490)
          if (
            bottomHeight >= topHeight ||
            bottomHeight >= 330 ||
            (rectDropdown?.height && rectDropdown.height <= bottomHeight)
          ) {
            setDropdownMaxHeight(bottomHeight)
            setIsTop(false)
          } else {
            setDropdownMaxHeight(topHeight)
            setIsTop(true)
          }
        }
      })
    } else if (searchValue !== '') {
      setTimeout(() => {
        onSearchInternal('')
      }, 200)
    }
    onDropdownVisibleChange?.(newOpen)
    setOpenInternal(newOpen)
  }

  const onOpenTreeInternal = (key: string | number | null) => {
    if (!key) {
      return
    }
    onOpenTree?.(key)
  }

  const apply = () => {
    const newValues =
      Array.isArray(valueInternal) &&
      valueInternal.includes(SELECT_ALL) &&
      (showApplyButton || isCustomApply) &&
      showSelectAll
        ? (valueInternal.filter((item) => item !== SELECT_ALL) as ValueType)
        : valueInternal

    setValueApplied(newValues)
    onDropdownVisibleChangeInternal(false)
    onChange?.(newValues as ValueType, selectedOptions)
    return newValues ?? undefined
  }

  const cancel = () => {
    setValueInternal(null)
    setValueApplied(null)
    setSelectedOptions([])
    onDropdownVisibleChangeInternal(false)
    onChange?.((multiple ? [] : null) as unknown as ValueType, [])
  }

  const dropdownRenderInternal = (dropdown: ReactElement) =>
    dropdownRender ? dropdownRender(dropdown, apply) : dropdown

  useIntersectionObserver(
    refScroll,
    (isInViewport) => {
      if (isInViewport && refScroll.current && defaultScrollIndex !== undefined) {
        const scrollItem = refScroll.current.querySelector('.rc-virtual-list-holder-inner')?.childNodes?.[
          defaultScrollIndex
        ] as HTMLDivElement
        if (scrollItem) {
          refScroll.current.scrollTop = scrollItem.offsetTop
        }
      }
    },
    { disable: defaultScrollIndex === undefined },
  )

  useEffect(() => {
    if (
      multiple &&
      showSelectAll &&
      !isTree &&
      options &&
      !searchValue &&
      !hasNextPage &&
      selectedOptions.length > 0 &&
      selectedOptions.length === options.length
    ) {
      setValueInternal((prev) => uniq([SELECT_ALL, ...((prev as any[]) || [])]) as ValueType)
    }
  }, [showSelectAll, options, selectedOptions, searchValue, hasNextPage, multiple, isTree])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!openInternal) {
      timer = setTimeout(() => {
        setIsTop(false)
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [openInternal])

  useEffect(() => {
    if (defaultValue && (value === null || value === undefined)) {
      onChangeInternal(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    setOpenInternal(open)

    if (open !== undefined) {
      onDropdownVisibleChangeInternal(open)
    }
  }, [open])

  useEffect(() => {
    if (isValueCleared) {
      setValueInternal(null)
      if (showApplyButton) {
        setValueApplied(null)
        setSelectedOptions([])
      }
    }
  }, [isValueCleared])

  useDeepCompareEffect(() => {
    if (typeof value !== 'undefined') {
      setValueInternal((prev) => {
        if (
          !isEqual(Array.isArray(prev) ? prev.filter((item) => item !== SELECT_ALL).sort() : prev, valueSorted) ||
          (options && selectedOptions.length === 0)
        ) {
          setSelectedOptions(getCurrentOptions((value ?? []) as ValueType))
          return value
        }
        return prev
      })
      setValueApplied((prev) => {
        if (!isEqual(prev, value)) {
          return value
        }
        return prev
      })
    }
  }, [valueSorted, options])

  useEffect(() => {
    if (initialSelectAll && value === undefined && multiple && showSelectAll && !loading) {
      const newApplied = options?.map((item) => item.value) || []
      const newValue = uniq([SELECT_ALL, ...newApplied]) as ValueType
      setValueInternal(newValue)
      setValueApplied(newApplied as ValueType)
      setSelectedOptions(getCurrentOptions(newValue))
    }
  }, [initialSelectAll, loading, options])

  useUpdateEffect(() => {
    if (value === null || value === undefined) {
      setValueInternal(null)
      setValueApplied(null)
    }
  }, [value])

  useEffect(() => {
    if (typeof closeSelectTrigger !== 'undefined') {
      onDropdownVisibleChangeInternal(false)
    }
  }, [closeSelectTrigger])

  useEventListener(
    'wheel',
    (event) => {
      if (!(event.target as HTMLElement | null)?.closest('.rc-select-dropdown')) {
        setOpenInternal(false)
        onDropdownVisibleChange?.(false)
      }
    },
    { target: getPopupContainerInternal() },
  )

  useEffect(() => {
    if (refWrap.current) {
      const input = refWrap.current.querySelector('input') as HTMLInputElement & CustomChange
      if (input) {
        input.setValue = onInsertChange
      }
    }
  }, [refWrap.current])

  useSyncWidth(refLabel, refWrap, 47, [], !!widthMaxContent && multiple)

  return (
    <div
      className={cx(
        classes.wrap,
        className,
        classes[size],
        classes[type],
        {
          defaultReset: defaultIsReset && isEqual(valueInternal, defaultValue),
          [classes.error]: isError,
          'rc-select-wrap-open': openInternal,
          'rc-select-max-content': widthMaxContent,
          [classes.disabled]: disabled,
        },
        classNameOpen && {
          [classNameOpen]: openInternal,
        },
        classNameDisabled && {
          [classNameDisabled]: disabled,
        },
      )}
      ref={refWrap}
    >
      <div className={cx(classes.cont, classNameContainer)}>
        {((labelInternal && multiple) ||
          isValueStringFromProps ||
          (labelInternal && showSearch && searchInDropdown) ||
          (defaultIsReset && isEqual(valueInternal, defaultValue)) ||
          (labelInternal && labelWithItems)) && (
          <span
            className={cx(classes.label, 'rc-label', classNameLabel, {
              [classes.labelWithInfo]: showWarningIcon,
              [classes.labelPlaceholder]:
                labelInternal !== valueStringWhenNotLoaded &&
                (showApplyButton
                  ? valueApplied === null || valueApplied === undefined || isEqual(valueApplied, [])
                  : valueInternal === null || valueInternal === undefined || isEqual(valueInternal, [])),
            })}
            ref={refLabel}
          >
            <span
              onMouseDown={() => {
                const isOpen = getOpenInternal()
                requestAnimationFrame(() => {
                  if (!isOpen) {
                    onDropdownVisibleChangeInternal(true)
                  }
                })
              }}
            >
              {labelInternal}
              {counter > 0 && <span className={classes.counter}>+{counter}</span>}
            </span>
          </span>
        )}

        <RcSelect
          allowClear={isCleanable && !!valueInternal}
          animation="slide-up"
          className={cx(
            classNameSelect,
            classes.select,
            {
              [classes.isChanged]: isChanged,
              'rc-select-cell': type === SelectTypes.Cell,
              'rc-select-cell-selected':
                (isEmpty(valueCombine) && !isEmpty(valueStringWhenNotLoaded)) || !isEmpty(valueCombine),
              'rc-select-error': isError,
              'rc-select-no-editable': noEditable,
              'rc-select-small': size === SelectSizes.Small,
              'rc-select-large': size === SelectSizes.Large,
              'rc-select-hide-label': labelWithItems || (showSearch && searchInDropdown),
              'rc-select-search-in-dropdown': showSearch && searchInDropdown,
              'rc-select-with-search': showSearch && !searchInDropdown,
              'rc-select-without-search-input': !showSearch || (showSearch && searchInDropdown),
            },
            classNameOpenSelect && {
              [classNameOpenSelect]: openInternal,
            },
          )}
          clearIcon={<CloseIcon />}
          data-type="text"
          disabled={disabled || noEditable}
          dropdownAlign={{
            offset: [offsetLeft, isTop ? -offsetTop : offsetTop],
            overflow: { adjustX: false, adjustY: false },
          }}
          dropdownRender={(menu) => (
            <div
              className={cx(dropdownClassName, {
                'rc-select-dropdown-cont': !dropdownNotStyled,
                'rc-multiple-dropdown': multiple,
                'rc-dropdown-cell': type === SelectTypes.Cell,
                'rc-select-small': size === SelectSizes.Small || type === SelectTypes.Cell,
                'rc-select-large': size === SelectSizes.Large,
                'rc-select-ellipsis-options': isOptionEllipsis,
                'rc-dropdown-with-search': showSearch && searchInDropdown,
                'rc-dropdown-with-apply': showApplyButton,
                [classes.fetchingNextPage]: isFetchingNextPage,
              })}
              ref={refDropdown}
              style={{
                minWidth: dropdownMinWidth ?? 'auto',
                maxHeight: dropdownMaxHeight ?? 'auto',
              }}
            >
              {dropdownRenderInternal(
                <>
                  {showSearch && searchInDropdown && (
                    <div className={cx(classes.search, 'rc-select-search-input')}>
                      <TextField
                        isCleanable
                        onChange={onSearchInternal}
                        placeholder={searchPlaceholder ?? t('search')}
                        ref={refSearchInput}
                        showSearchIcon={showSearchIcon}
                        value={searchValueCombine}
                      />
                    </div>
                  )}
                  {!loading && !error && (
                    <div
                      className="scroll scroll-mini"
                      onScroll={(e) => {
                        const target = e.currentTarget
                        if (
                          !loading &&
                          hasNextPage &&
                          target.scrollHeight - target.scrollTop - target.clientHeight < 100
                        ) {
                          onScrollPaginate?.()
                        }
                      }}
                      ref={refScroll}
                    >
                      {menu}
                    </div>
                  )}
                  {loading && (
                    <div className={cx(classes.loadingList, 'rc-select-loader')}>
                      <Skeleton size={SkeletonSizes.Small} />
                      <Skeleton size={SkeletonSizes.Small} />
                      <Skeleton size={SkeletonSizes.Small} />
                      <Skeleton size={SkeletonSizes.Small} />
                    </div>
                  )}

                  {(!!error || (error as any) === 0) && !loading && (
                    <MarkItem className="p0-8" type={MarkItemTypes.Danger}>
                      {getError(error)}
                    </MarkItem>
                  )}
                  <Loader
                    className={cx('loader-paginate', classes.loaderPaginate, {
                      [classes.withConfirm]: showApplyButton,
                    })}
                    color={LoaderColors.Gray}
                    type={LoaderTypes.Paginate}
                  />

                  {showApplyButton && (
                    <ConfirmButtons
                      cancelText={t('reset')}
                      className={classes.confirmButtons}
                      disabledCancel={Array.isArray(valueInternal) ? !valueInternal.length : !valueInternal}
                      disabledOk={applied}
                      isLoading={isLoadingApplyButton}
                      okText={t('apply')}
                      onCancel={cancel}
                      onOk={apply}
                    />
                  )}
                </>,
              )}
            </div>
          )}
          filterOption={
            !isTree
              ? (inputValue, option) =>
                  typeof option?.searchValue === 'string' &&
                  option?.searchValue.toLowerCase().includes(inputValue.toLowerCase())
              : undefined
          }
          getPopupContainer={getPopupContainerInternal}
          inputIcon={type === SelectTypes.Cell ? <ArrowMicroIcon /> : <ArrowIcon />}
          menuItemSelectedIcon={<MarkIcon />}
          mode={multiple ? 'multiple' : undefined}
          notFoundContent={notFoundContent || t('notFound')}
          onChange={onChangeInternal}
          onDeselect={onDeselect}
          onSearch={showSearch && !searchInDropdown ? onSearchInternal : undefined}
          options={
            !isTree
              ? (options?.length
                  ? showSelectAll && multiple
                    ? [
                        {
                          label: (
                            <>
                              {isFetchingAllPages && (
                                <Loader
                                  className={cx(classes.loaderSelectAll, 'fetch-all-loader')}
                                  color={LoaderColors.Dark}
                                  type={LoaderTypes.SpinnerMini}
                                />
                              )}
                              {t('selectAll')}
                            </>
                          ),
                          value: SELECT_ALL,
                          className: 'rc-select-item-option-underlined',
                        } as OptionType & OptionTreeItem,
                        ...options,
                      ]
                    : options
                  : undefined
                )?.map(
                  (option) =>
                    ({
                      ...option,
                      className: cx(option.className, {
                        'rc-select-item-option-highlighted': option.highlighted,
                        'rc-select-item-option-underlined': option.underlined,
                      }),
                      searchValue: option.value === SELECT_ALL ? searchValue : String(option.label),
                      label:
                        option.truncate || option.description ? (
                          <div>
                            <div
                              className={cx('rc-select-option-label', { 'rc-select-option-label-bold': option.isBold })}
                            >
                              {option.truncate ? (
                                <Truncate
                                  disabledTooltip={option.truncateDisabledTooltip}
                                  lines={option.truncateLines}
                                  offsetX={6}
                                >
                                  {option.label}
                                </Truncate>
                              ) : (
                                option.label
                              )}
                            </div>
                            {option.description && (
                              <div className="rc-select-option-description">{option.description}</div>
                            )}
                          </div>
                        ) : (
                          <span className={cx({ 'rc-select-option-label-bold': option.isBold })}>{option.label}</span>
                        ),
                      title: '',
                    }) as DefaultOptionType,
                )
              : undefined
          }
          placeholder={(labelInternal && multiple) || isValueStringFromProps ? undefined : (placeholder ?? label)}
          ref={refSelect}
          searchValue={searchValueCombine}
          showArrow={!isCleanable || (Array.isArray(valueInternal) ? !(valueInternal as [])?.length : !valueInternal)}
          showSearch={!searchInDropdown ? showSearch : false}
          virtual={false}
          {...props}
          dropdownClassName={isTop ? 'dropdown-top' : undefined}
          onDropdownVisibleChange={onDropdownVisibleChangeInternal}
          open={openInternal}
          placement={isTop ? 'topLeft' : 'bottomLeft'}
          value={valueCombine}
        >
          {!isTree
            ? children
            : itemsTreeParse({
                itemsTree: options,
                openTreeItems,
                setOpenTreeItems,
                isSearch: isSearchCombine,
                onOpenTree: onOpenTreeInternal,
                checkedValues: valueCombine,
                selectedParentIds,
                isSelectable: true,
                multiple,
                selectedOptions,
                isSelectWithChildren,
                checkParentWithCheckedChildren,
              })}
        </RcSelect>
      </div>

      {isError && showErrorIcon && (
        <Tooltip
          className={classes.info}
          classNameDropdown={cx(classNameTooltip, 'select-error-tooltip')}
          isHovered
          tooltip={tooltip ?? (error ? getError(error) : undefined)}
        >
          <ErrorIcon className={cx(classes['warning-danger'], 'select-error-icon')} />
        </Tooltip>
      )}
      {showWarningIcon && (
        <Tooltip
          className={classes.info}
          classNameDropdown={cx(classNameTooltip, 'select-warning-tooltip')}
          isHovered
          tooltip={tooltip}
        >
          <InfoIcon className={cx(classes[`warning-${warningColor}`], 'select-warning-icon')} />
        </Tooltip>
      )}
      {showApplyButton && (
        <div className={cx(classes.notApplied, { [classes.notAppliedDisabled]: applied || openInternal })}>
          <Tooltip className={classes.info} classNameDropdown={classNameTooltip} isHovered tooltip={t('notApplied')}>
            <InfoIcon className={classes['warning-danger']} />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export const Select = forwardRef(InternalSelect) as <
  ValueType extends string | number | (number | string)[] = string,
  Meta = unknown,
  Multiple extends boolean = boolean,
  ShowApplyButton extends boolean = boolean,
>(
  props: Omit<
    SelectProps<ValueType>,
    'onChange' | 'mode' | 'getPopupContainer' | 'options' | 'value' | 'dropdownRender'
  > &
    SelectPropsInternal<ValueType, Meta, Multiple, ShowApplyButton> & { ref?: Ref<BaseSelectRef> },
) => ReactElement
