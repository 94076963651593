import { GetAllData } from '@jume/api'
import { TransitionTableRow } from 'jume/transitionTable/api'
import { PaginateData } from 'packages/react-query/src/types'

export enum TransitionTabsCodes {
  All = 'all',
  Completed = 'completed',
}

export type TransitionAllData = Record<TransitionTabsCodes, PaginateData<GetAllData<TransitionTableRow>> | undefined>
